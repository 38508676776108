/* eslint-disable react/prop-types */
// @mui
import { Box, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// components
import AuthButton from '../../../components/buttons/AuthButton';
import { Loader } from '../../../components/Loader';
import MobilePhoneInput from '../../../components/MobilePhoneInput';
// hooks
import useLocales from '../../../hooks/useLocales';
import AuthBaseLayout from '../../../layouts/AuthBaseLayout';
import { FooterContainer } from '../../../layouts/AuthBaseLayout.elements';
// store
import * as authActions from '../../../models/auth/actions';
import { disableButton } from '../Auth.utils';
import { registerSchema } from '../Auth.validationSchemas';

const Registration = ({ isReset = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('product')) {
      const product = queryParams.get('product');
      // Store productId in local storage
      localStorage.setItem('selected_product', product);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem('tenant_id');
    localStorage.removeItem('token');
    localStorage.removeItem('user_role_id');
    localStorage.removeItem('workspaces');
    localStorage.removeItem('tenant_url');
    localStorage.removeItem('tenant_role');
    localStorage.removeItem('tenant_index');
  }, []);

  const formik = useFormik({
    initialValues: {
      phone: '',
      code: '+966',
    },
    registerSchema,
    onSubmit: (values) => {
      const { code, phone } = values;
      setLoading(true);
      dispatch(
        authActions.sendOtpRequest({ mobileNumber: `${code.substring(1)}${phone}`, callback: handleSendOtpCallback })
      );
    },
  });

  const handleSendOtpCallback = (type, res) => {
    setLoading(false);
    if (type === 'success') {
      navigate('/verify-code', {
        state: { mobileNumber: `${formik.values.code}${formik.values.phone}`, isReset },
      });
    } else {
      toast.error(res.message);
    }
  };

  const isButtonDisabled = disableButton(['phone'], formik) || loading;

  return (
    <AuthBaseLayout
      heading={translate(isReset ? 'auth.forgotPassword.heading' : 'auth.registration.heading')}
      subtitle={translate(isReset ? 'auth.forgotPassword.subtitle' : 'auth.registration.subtitle')}
    >
      <div>
        <form onSubmit={formik.handleSubmit}>
          <MobilePhoneInput
            onChange={(phone) => {
              formik.setFieldValue('phone', phone || '');
            }}
          />

          <AuthButton text={translate('auth.registration.verifyMobile')} isDisabled={isButtonDisabled} />

          {loading && <Loader />}

          {isReset && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: '30px' }}
            >
              <Typography variant="caption">{translate('auth.login.noAccount')}</Typography>
              <Link
                component="button"
                variant="caption"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  navigate('/registration');
                }}
                sx={{ textDecoration: 'underline', color: '#0066FF', marginLeft: '10px' }}
              >
                {translate('auth.login.signUp')}
              </Link>
            </Box>
          )}
          <FooterContainer>
            <Typography variant="caption">{translate('auth.registration.alreadyAccount')}</Typography>
            <Link
              component="button"
              variant="caption"
              onClick={(event) => {
                event.preventDefault();
                navigate('/login');
              }}
              sx={{ textDecoration: 'underline', color: '#0066FF', marginLeft: '10px' }}
            >
              {translate('auth.registration.login')}
            </Link>
          </FooterContainer>
        </form>
      </div>
    </AuthBaseLayout>
  );
};

export default Registration;
