import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  loading: false,
  invoices: null,
};

const reducer = createReducer(initialState, {
  [actions.getInvoicesRequest]: (state) => {
    state.loading = true;
  },
  [actions.getInvoicesSuccess]: (state, action) => {
    state.loading = false;
    state.invoices = action.payload.data;
  },
  [actions.getInvoicesFailure]: (state) => {
    state.loading = false;
  },
});

const loading = (state) => state.invoices.loading;
const invoices = (state) => state.invoices.invoices;

const selectors = {
  loading,
  invoices,
};

export { initialState, reducer, selectors };
export default reducer;
