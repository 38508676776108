import {
  Box,
  Button,
  debounce,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import * as Yup from 'yup';

import ContactSearchBox from '../../../components/ContactSearchBox';
import { Loader } from '../../../components/Loader';
import PropertyCard from '../../../components/PropertyCard';
import useLocales from '../../../hooks/useLocales';
import * as propertiesActions from '../../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../../models/properties/reducers';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as reservationsActions from '../../../models/t-reservations/actions';
import reservationServices from '../../../models/t-reservations/services';
import { dispatch } from '../../../redux/store';

const CustomPickersDay = styled(({ isCheckIn, isBetween, isCheckOut, ...other }) => <PickersDay {...other} />)(
  ({ theme, isCheckIn, isCheckOut, isBetween }) => ({
    ...(isCheckIn && {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      [theme.direction === 'rtl' ? 'borderTopRightRadius' : 'borderTopLeftRadius']: '50%',
      [theme.direction === 'rtl' ? 'borderBottomRightRadius' : 'borderBottomLeftRadius']: '50%',
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
    }),
    ...(isCheckOut && {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      [theme.direction === 'rtl' ? 'borderTopLeftRadius' : 'borderTopRightRadius']: '50%',
      [theme.direction === 'rtl' ? 'borderBottomLeftRadius' : 'borderBottomRightRadius']: '50%',
    }),
    ...(isBetween && {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      border: `2px solid ${theme.palette.primary.main}`,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
    }),
  })
);

function DayComponent(props) {
  const { day = dayjs(), checkInDate, checkOutDate, ...other } = props;
  const isCheckInDate = dayjs(day).isSame(checkInDate, 'day');
  const isCheckOutDate = dayjs(day).isSame(checkOutDate, 'day');

  const isBetween = dayjs(day).isAfter(checkInDate, 'day') && dayjs(day).isBefore(checkOutDate, 'day');

  return (
    <CustomPickersDay
      {...other}
      day={day}
      isCheckIn={isCheckInDate}
      isBetween={isBetween}
      isCheckOut={isCheckOutDate}
    />
  );
}

const reservationCreationSchema = Yup.object({
  property_id: Yup.string().required(),
  check_in_date: Yup.string().required(),
  check_out_date: Yup.string().required(),
  tenant_contact_id: Yup.string().required(),
  name: Yup.string().required(),
  mobile_number: Yup.string().required(),
  payment_method: Yup.string().oneOf(['is_bank_transfer', 'is_online_payment']).required('Required'),
});

const AddReservationModal = ({ open, onClose }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const tenantRole = localStorage.getItem('tenant_role');
  const isDisabled = tenantRole === '6';

  const properties = useSelector(propertiesSelectors.properties);
  const search = useSelector(propertiesSelectors.search);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activePaySubscription = activeSubscriptions?.find((sub) => sub.solution === 'pay') || null;

  const [disabledDates, setDisabledDates] = useState([]);

  const [openCheckOutDate, setOpenCheckOutDate] = useState(false);
  const [openCheckInDate, setOpenCheckInDate] = useState(false);

  const [allowedCheckoutDate, setAllowedCheckoutDate] = useState(null);

  const [availability, setAvailability] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);

  const items = searchValue ? search : properties;

  const debouncedCheckAvailability = useDebouncedCallback((requestData) => {
    reservationServices.checkAvailability(requestData).then((response) => {
      const newCheckInDate = dayjs(response.data.data.allowed_check_in_date);

      if (!newCheckInDate.isSame(formik.values.check_in_date, 'day')) {
        formik.setFieldValue('check_in_date', newCheckInDate);
      }

      setAvailability(response.data.data.blocked_dates);
      if (response.data.data?.allowed_check_out_date === null) {
        setAllowedCheckoutDate(null);
      } else {
        setAllowedCheckoutDate(dayjs(response.data.data?.allowed_check_out_date));
      }
    });
  }, 100);

  const callback = (type, response) => {
    if (type === 'success') {
      toast.success(translate('siyaha.reservation.successCreation'));
      navigate(`/siyaha/reservations/${response.data.id}`);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      property_id: '',
      check_in_date: dayjs(),
      check_out_date: dayjs().add(1, 'day'),
      tenant_contact_id: '',
      name: '',
      email: '',
      mobile_number: '',
      payment_method: '',
    },
    isInitialValid: false,
    validationSchema: reservationCreationSchema,
    onSubmit: (values) => {
      const formattedValues = {
        ...values,
        check_in_date: values.check_in_date.format('YYYY-MM-DD'),
        check_out_date: values.check_out_date.format('YYYY-MM-DD'),
      };

      setLoading(true);
      dispatch(reservationsActions.createReservationRequest({ ...formattedValues, callback }));
    },
  });

  const shouldDisableDate = (date) => {
    const formattedDate = new Date(date);
    if (disabledDates.length === 0) {
      return false;
    }
    return disabledDates.some(
      (disabledDate) =>
        formattedDate.getFullYear() === disabledDate.getFullYear() &&
        formattedDate.getMonth() === disabledDate.getMonth() &&
        formattedDate.getDate() === disabledDate.getDate()
    );
  };

  useEffect(() => {
    if (availability?.length > 0) {
      const blockedDates = availability?.map((date) => new Date(date));
      setDisabledDates(blockedDates);
    } else {
      setDisabledDates([]);
    }
  }, [availability]);

  useEffect(() => {
    if (selectedProperty?.id && formik.values.check_in_date) {
      const requestData = {
        date: formik.values.check_in_date.format('YYYY-MM-DD'),
        propertyId: selectedProperty?.id,
      };
      debouncedCheckAvailability(requestData);

      return () => debouncedCheckAvailability.cancel();
    }
  }, [formik.values.check_in_date, selectedProperty?.id]);

  useEffect(() => {
    if (formik.values.check_in_date.isAfter(formik.values.check_out_date)) {
      formik.setFieldValue('check_out_date', formik.values.check_in_date.add(1, 'day'));
    }
  }, [formik.values.check_in_date]);

  useEffect(() => {
    dispatch(propertiesActions.getPropertiesRequest({ include_archived: 0, is_siyaha: true }));
  }, []);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value) {
      dispatch(
        propertiesActions.searchPropertiesRequest({
          include_archived: 0,
          unit_number: value,
          is_siyaha: true,
        })
      );
    }
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty((prevSelected) => {
      const newSelectedProperty = prevSelected === property ? null : property;
      formik.setFieldValue('property_id', newSelectedProperty?.id || '');
      return newSelectedProperty;
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography sx={{ p: 1 }} variant="h5">
          {translate('siyaha.reservation.createReservation')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            onChange={debounce(onSearch, 200)}
            label={translate('deal.searchByUnitNumber')}
          />

          <Grid container spacing={2} sx={{ maxHeight: 240, minHeight: 240, overflow: 'auto' }}>
            {loading && <Loader />}
            {items &&
              items.map((p) => (
                <Grid item xs={12} sm={6} key={p.id}>
                  <PropertyCard
                    row
                    property={p}
                    cardHeight={'165px'}
                    clickable
                    selected={selectedProperty === p}
                    onClick={() => handlePropertySelect(p)}
                  />
                </Grid>
              ))}
          </Grid>

          <Box sx={{ pt: 2 }}>
            <FormLabel sx={{ color: '#212B36', mt: 2 }}>{translate('siyaha.reservation.guestName')}</FormLabel>
            <Box sx={{ display: 'flex', w: 1, mb: 4 }}>
              <ContactSearchBox name="tenant_contact_id" formik={formik} filters={{ is_siyaha: true }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            <DatePicker
              name="check_in_date"
              label={translate('siyaha.reservation.checkInDate')}
              shouldDisableDate={shouldDisableDate}
              minDate={dayjs().toDate()}
              value={formik.values.check_in_date.toDate()}
              onChange={(newValue) => {
                formik.setFieldValue('check_in_date', dayjs(newValue));
                setOpenCheckOutDate(true);
              }}
              open={openCheckInDate}
              onOpen={() => setOpenCheckInDate(true)}
              onClose={() => setOpenCheckInDate(false)}
              slotProps={{
                textField: {
                  onClick: (e) => {
                    if (openCheckInDate) return;
                    setOpenCheckInDate(true);
                  },
                },
              }}
              disabled={!formik.values.property_id}
            />
            <DatePicker
              dir="ltr"
              name="checkOutDate"
              label={translate('siyaha.reservation.checkOutDate')}
              // shouldDisableDate={shouldDisableDate}
              minDate={formik.values.check_in_date.add(1, 'day').toDate()}
              maxDate={allowedCheckoutDate?.toDate()}
              value={formik.values.check_out_date.toDate()}
              onChange={(newValue) => {
                formik.setFieldValue('check_out_date', dayjs(newValue));
              }}
              open={openCheckOutDate}
              onOpen={() => setOpenCheckOutDate(true)}
              onClose={() => {
                setOpenCheckOutDate(false);
              }}
              slots={{ day: DayComponent }}
              slotProps={{
                day: { checkInDate: formik.values.check_in_date, checkOutDate: formik.values.check_out_date },
                textField: {
                  onClick: (e) => {
                    if (openCheckOutDate) return;
                    setOpenCheckOutDate(true);
                  },
                },
              }}
              style={{ direction: 'ltr' }}
              disabled={!formik.values.property_id}
            />
          </Box>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography sx={{ fontWeight: 'bold', marginTop: '10px' }} color={theme.palette.text.primary}>
                  {translate('siyaha.reservation.paymentMethod')}
                </Typography>
              </FormLabel>
              <RadioGroup name="payment_method" value={formik.values.payment_method} onChange={formik.handleChange}>
                <FormControlLabel
                  value="is_bank_transfer"
                  control={<Radio />}
                  label={translate('siyaha.reservation.paymentMethods.bankTransfer')}
                />
                <Grid item xs={12}>
                  <FormControlLabel
                    value="is_online_payment"
                    control={<Radio />}
                    label={translate('siyaha.reservation.paymentMethods.online')}
                    disabled={activePaySubscription === null}
                  />
                  {activePaySubscription === null && (
                    <>
                      <span
                        style={{
                          backgroundColor: '#ddd',
                          marginLeft: 7,
                          marginRight: 7,
                          paddingRight: 12,
                          paddingLeft: 12,
                          paddingTop: 3,
                          paddingBottom: 3,
                          borderRadius: 5,
                        }}
                      >
                        {translate('setting.paymentSetting.requiresNuzulPay')}
                      </span>
                    </>
                  )}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </form>

        <Divider sx={{ mt: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
          <Button variant="outlined" sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }} onClick={onClose}>
            {translate('siyaha.reservation.cancel')}
          </Button>

          <Button disabled={!formik.isValid} variant="contained" sx={{ color: 'white' }} onClick={formik.submitForm}>
            {translate('siyaha.reservation.create')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddReservationModal;
