// @mui
import DownloadIcon from '@mui/icons-material/Download';
import { Card, CardContent, Container, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import * as invoicesActions from '../../models/invoices/actions';
import { selectors as invoicesSelectors } from '../../models/invoices/reducers';

// ----------------------------------------------------------------------

export default function Invoices() {
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedProduct = localStorage.getItem('selected_product');

  const invoices = useSelector(invoicesSelectors.invoices);
  const invoicesLoading = useSelector(invoicesSelectors.loading);

  useEffect(() => {
    dispatch(invoicesActions.getInvoicesRequest());
  }, []);

  const columns = [
    {
      field: 'invoice_number',
      headerName: '#',
      maxWidth: 100,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.api.getRowIndex(params.row.id) + 1,
    },
    {
      field: 'invoice_date',
      headerName: translate('invoice.invoiceDate'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => row.created_at.split('T')[0],
    },
    {
      field: 'invoice_description',
      headerName: translate('invoice.invoiceDescription'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.description_ar : row.description_en || '-'),
    },
    {
      field: 'payment_method',
      headerName: translate('invoice.paymentMethod'),
      maxWidth: 280,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) =>
        languageCode === 'ar' ? row.payment_method_desc_ar : row.payment_method_desc_en || '-',
    },
    {
      field: 'total_amount',
      headerName: translate('invoice.totalAmount'),
      headerClassName: 'datagrid-header',
      flex: true,
      align: 'center',
      headerAlign: 'center',
      valueGetter: ({ row }) => `${row.zatca_invoice_amount} ${translate('SAR')}`,
    },
    {
      field: 'pdf_file',
      headerName: translate('invoice.pdfFile'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => (
        <div>
          <span>{translate('invoice.downloadInvoice')}</span>
          <IconButton href={`${row?.invoice_url}?download=true`} download target="_blanck">
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Page title={translate('settings')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('billing')}
        </Typography>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <DataGrid
              loading={invoicesLoading}
              getRowClassName={`clickable`}
              columns={columns}
              rows={invoices || []}
              disableColumnFilter
              disableColumnSelector
              hideFooterSelectedRowCount
              disableSelectionOnClick
              rowsPerPageOptions={[10]}
              autoHeight
              pagination
              sx={{
                '& .datagrid-header': {
                  backgroundColor: '#F4F6F8',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderRadius: 0,
                },
                border: 'none',
              }}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
