import { createAction } from '@reduxjs/toolkit';

export const getTOrdersRequest = createAction('GET_T_ORDERS_REQUEST');
export const getTOrdersSuccess = createAction('GET_T_ORDERS_SUCCESS');
export const getTOrdersFailure = createAction('GET_T_ORDERS_FAILURE');

export const getTOrderDetailsRequest = createAction('GET_T_ORDER_DETAILS_REQUEST');
export const getTOrderDetailsSuccess = createAction('GET_T_ORDER_DETAILS_SUCCESS');
export const getTOrderDetailsFailure = createAction('GET_T_ORDER_DETAILS_FAILURE');

export const createTOrderDownPaymentRequest = createAction('CREATE_T_ORDER_DOWN_PAYMENT_REQUEST');
export const createTOrderDownPaymentSuccess = createAction('CREATE_T_ORDER_DOWN_PAYMENT_SUCCESS');
export const createTOrderDownPaymentFailure = createAction('CREATE_T_ORDER_DOWN_PAYMENT_FAILURE');

export const createTOrderServiceRequest = createAction('CREATE_T_ORDER_SERVICE_REQUEST');
export const createTOrderServiceSuccess = createAction('CREATE_T_ORDER_SERVICE_SUCCESS');
export const createTOrderServiceFailure = createAction('CREATE_T_ORDER_SERVICE_FAILURE');

export const setTOrderStatusRequest = createAction('SET_T_ORDER_STATUS_REQUEST');
export const setTOrderStatusSuccess = createAction('SET_T_ORDER_STATUS_SUCCESS');
export const setTOrderStatusFailure = createAction('SET_T_ORDER_STATUS_FAILURE');
