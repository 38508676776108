import { Box, Button, Container } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import InfoBox from '../../../components/InfoBox';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import * as tOrdersActions from '../../../models/t-orders/actions';
import { selectors as tOrdersSelectors } from '../../../models/t-orders/reducers';
import OrderForm from './OrderForm';

const EditOrder = () => {
  const { id } = useParams();

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const tOrder = useSelector(tOrdersSelectors.tOrderDetails);

  const callback = (type) => {
    if (type === 'success') {
      toast.success(translate('order.successCancellation'));
      dispatch(tOrdersActions.getTOrderDetailsRequest(id));
    } else {
      toast(translate('Error'), { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      id,
      tenant_id: tOrder?.tenant_id || '',
      total_amount_with_tax: tOrder?.total_amount_with_tax || 0,
      total_amount_without_tax: tOrder?.total_amount_without_tax || 0,
      status: tOrder?.status || '',
      created_at: tOrder?.created_at || '',
      updated_at: tOrder?.updated_at || '',
      is_online_payment: !!tOrder?.is_online_payment,
      is_bank_transfer: !!tOrder?.is_bank_transfer,
      is_cash: !!tOrder?.is_cash,
      myf_initiate_session: tOrder?.myf_initiate_session || '',
      transactions: tOrder?.transactions || [],
      tenant_contact: {
        id: tOrder?.tenant_contact?.id || '',
        name: tOrder?.tenant_contact?.name || '',
        mobile_number: tOrder?.tenant_contact?.mobile_number || '',
        email: tOrder?.tenant_contact?.email || '',
      },
      reservation: tOrder?.reservation || null,
      property_order: tOrder?.property_order || null,
      service_order: tOrder?.service_order || null,
      product: tOrder?.product || '',
      description_ar: tOrder?.description_ar || '',
      description_en: tOrder?.description_en || '',
      invoice_url: tOrder?.invoice_url || '',
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(tOrdersActions.getTOrderDetailsRequest(id));
  }, [dispatch, id]);

  const createdAt = tOrder?.created_at || new Date();

  const handleCancel = () => {
    dispatch(tOrdersActions.setTOrderStatusRequest({ id, callback, status: 'canceled' }));
  };
  return (
    <Page title={translate('order.editPageTitle')}>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <InfoBox
            label={translate('order.editPageTitle')}
            number={tOrder?.id}
            date={createdAt}
            backButtonLink="/maktb/operations/orders"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                height: '100%',
                justifyContent: 'end',
              }}
            >
              {tOrder?.status === 'pending_payment' && (
                <Button
                  onClick={() => handleCancel()}
                  variant="outlined"
                  sx={{
                    color: 'error.main',
                    borderColor: 'error.main',
                    marginLeft: 2,
                    '&:hover': {
                      color: 'error.dark',
                      borderColor: 'error.dark',
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {translate('order.cancelButton')}
                </Button>
              )}
            </Box>
          </InfoBox>
        </Box>
        <OrderForm formik={formik} />
      </Container>
    </Page>
  );
};

export default EditOrder;
