import {
  Box,
  Button,
  debounce,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import ContactSearchBox from '../../../components/ContactSearchBox';
import { Loader } from '../../../components/Loader';
import PropertyCard from '../../../components/PropertyCard';
import useLocales from '../../../hooks/useLocales';
import { selectors as dealSelectors } from '../../../models/deals/reducers';
import * as propertiesActions from '../../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../../models/properties/reducers';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as tOrdersActions from '../../../models/t-orders/actions';
import TServices from '../../../models/t-services/services';
import { dispatch } from '../../../redux/store';

const orderCreationSchema = Yup.object({
  property_id: Yup.string().when('orderType', {
    is: 'downPayment',
    then: Yup.string().required('Property ID is required for Down Payment'),
    otherwise: Yup.string().notRequired(),
  }),
  service_id: Yup.string().when('orderType', {
    is: 'service',
    then: Yup.string().required('Service ID is required for Service'),
    otherwise: Yup.string().notRequired(),
  }),
  tenant_contact_id: Yup.string().required('Tenant Contact ID is required'),
  name: Yup.string().required('Name is required'),
  mobile_number: Yup.string().required('Mobile Number is required'),
  payment_method: Yup.string().required('Payment Method is required'),
});

const AddOrderModal = ({ open, onClose }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  const createLoading = useSelector(dealSelectors.createLoading);

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');
  const activePaySubscription = activeSubscriptions?.find((sub) => sub.solution === 'pay') || null;

  const [tServices, setTServices] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedService, setSelectedService] = useState('');

  const properties = useSelector(propertiesSelectors.properties);
  const search = useSelector(propertiesSelectors.search);

  const items = searchValue ? search : properties;

  useEffect(() => {
    TServices.getServices({
      is_online_payment_enabled: 1,
      base_price: 1,
      tax_percentage: 1,
    }).then((res) => {
      setTServices(res.data.data);
    });
  }, []);

  useEffect(() => {
    dispatch(
      propertiesActions.getPropertiesRequest({
        include_archived: 0,
        is_office: true,
        availability_status: 'available',
        is_online_payment_enabled: 1,
        down_payment_price: 1,
        down_payment_tax_percentage: 1,
      })
    );
  }, []);

  const handleOrderTypeChange = (event) => {
    formik.setFieldValue('orderType', event.target.value);
  };

  const callback = (type, response) => {
    if (type === 'success') {
      toast.success(translate('order.successCreation'));
      navigate(`/maktb/operations/orders/${response.data.uuid}`);
    } else {
      toast(translate('Error'), { type: 'error' });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      orderType: 'downPayment',
      property_id: '',
      service_id: '',
      tenant_contact_id: '',
      name: '',
      email: '',
      mobile_number: '',
      payment_method: '',
    },
    isInitialValid: false,
    validationSchema: orderCreationSchema,
    onSubmit: (values) => {
      setLoading(true);

      if (values.orderType === 'downPayment') {
        dispatch(tOrdersActions.createTOrderDownPaymentRequest({ ...values, callback }));
      } else {
        dispatch(tOrdersActions.createTOrderServiceRequest({ ...values, callback }));
      }
    },
  });

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value) {
      dispatch(
        propertiesActions.searchPropertiesRequest({
          include_archived: 0,
          unit_number: value,
          is_office: true,
          availability_status: 'available',
          is_online_payment_enabled: 1,
          down_payment_price: 1,
          down_payment_tax_percentage: 1,
        })
      );
    }
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty((prevSelected) => {
      const newSelectedProperty = prevSelected === property ? null : property;
      formik.setFieldValue('property_id', newSelectedProperty?.id || '');
      return newSelectedProperty;
    });
  };

  const handleServiceChange = (event) => {
    formik.setFieldValue('service_id', event.target.value);
    setSelectedService(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 1, p: 2 }}>
        <Typography sx={{ p: 2 }} variant="h5">
          {translate('order.createOrder')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <button type="submit" style={{ display: 'none' }}>
            Submit
          </button>

          <>
            <FormLabel sx={{ color: '#212B36' }}>{translate('deal.contactName')}</FormLabel>
            <Box sx={{ display: 'flex', w: 1, mb: 2 }}>
              <ContactSearchBox name="tenant_contact_id" formik={formik} filters={{ is_office: true }} />
            </Box>
          </>

          <FormControl sx={{ p: 1 }}>
            <FormLabel sx={{ color: '#212B36' }}>{translate('order.type')}</FormLabel>

            <RadioGroup
              name="orderType"
              row
              sx={{ pl: 1 }}
              value={formik.values.orderType}
              onChange={handleOrderTypeChange}
            >
              <FormControlLabel label={translate('order.downPayment')} control={<Radio value="downPayment" />} />
              <FormControlLabel label={translate('order.service')} control={<Radio value="service" />} />
            </RadioGroup>
          </FormControl>

          {formik.values.orderType === 'downPayment' && (
            <Box sx={{ mb: 2 }}>
              <TextField
                sx={{ mb: 2 }}
                fullWidth
                onChange={debounce(onSearch, 200)}
                label={translate('deal.searchByUnitNumber')}
              />

              <Grid container spacing={2} sx={{ maxHeight: 240, minHeight: 240, overflow: 'auto' }}>
                {loading && <Loader />}
                {items &&
                  items.map((p) => (
                    <Grid item xs={12} sm={6} key={p.id}>
                      <PropertyCard
                        row
                        property={p}
                        cardHeight={'165px'}
                        clickable={!p.reserved}
                        selected={selectedProperty === p}
                        onClick={() => handlePropertySelect(p)}
                        reserved={p.reserved}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          )}

          {formik.values.orderType === 'service' && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="service-select-label">{translate('order.selectService')}</InputLabel>
              <Select
                labelId="service-select-label"
                value={selectedService}
                onChange={handleServiceChange}
                label={translate('order.selectService')}
              >
                {tServices.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {`${service.name_ar} - ${(
                      (parseFloat(service.base_price) || 0) +
                      (parseFloat(service.base_price) * (parseFloat(service.tax_percentage) / 100) || 0)
                    ).toFixed(2)} ${translate('SAR')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ p: 1 }}>
            <FormLabel>
              <Typography color={theme.palette.text.primary}>
                {translate('siyaha.reservation.paymentMethod')}
              </Typography>
            </FormLabel>
            <RadioGroup
              name="payment_method"
              row
              sx={{ pl: 1 }}
              value={formik.values.payment_method}
              onChange={formik.handleChange}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  value="is_online_payment"
                  control={<Radio />}
                  label={translate('siyaha.reservation.paymentMethods.online')}
                  disabled={activePaySubscription === null}
                />
                {activePaySubscription === null && (
                  <>
                    <span
                      style={{
                        backgroundColor: '#ddd',
                        marginLeft: 7,
                        marginRight: 7,
                        paddingRight: 12,
                        paddingLeft: 12,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 5,
                      }}
                    >
                      {translate('setting.paymentSetting.requiresNuzulPay')}
                    </span>
                  </>
                )}
              </Grid>
            </RadioGroup>
          </FormControl>
        </form>

        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <Button
            disabled={createLoading}
            variant="outlined"
            sx={{ color: 'black', borderColor: '#919EAB66', mr: 2 }}
            onClick={onClose}
          >
            {translate('order.cancel')}
          </Button>

          <Button
            disabled={!formik.isValid || createLoading}
            variant="contained"
            sx={{ color: 'white' }}
            onClick={formik.submitForm}
          >
            {!createLoading && translate('order.create')}
            {createLoading && <Loader />}
          </Button>
        </Box>
      </Box>

      {activePaySubscription === null && (
        <Box
          style={{
            backgroundColor: theme.palette.grey[300],
            paddingTop: 15,
            paddingBottom: 15,
            textAlign: 'center',
            color: 'black',
          }}
        >
          <Button
            variant="contained"
            sx={{ color: 'white', pt: 1, pb: 1 }}
            onClick={() => {
              navigate(`/pay/activations`);
            }}
          >
            {translate('order.activatePaySubscription')}
          </Button>
        </Box>
      )}
    </Dialog>
  );
};
export default AddOrderModal;
