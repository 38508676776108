import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Iconify from '../../../components/Iconify';
import PropertyCard from '../../../components/PropertyCard';
import { CLIENT_PORT, ENV } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import { selectors as userSelectors } from '../../../models/user/reducers';
import { TENANT_ROLES } from '../../../utils/constants';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const OrderForm = ({ formik = false }) => {
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  const workspace = useSelector(userSelectors.workspace);
  const userInfo = useSelector(userSelectors.me);

  const protocol = ENV === 'local' ? 'http' : 'https';
  const clientDomain = workspace?.client_domain;
  const orderId = formik.values?.id;
  const paymentLink =
    ENV === 'local'
      ? `${protocol}://${clientDomain}${CLIENT_PORT}/checkout/${orderId}`
      : `${protocol}://${clientDomain}/checkout/${orderId}`;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        '& .MuiFormHelperText-root': {
          textAlign: 'end',
        },
      }}
    >
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ mr: 1 }}>
                      {translate('contact.information')}
                    </Typography>
                    {!activeMaktbSubscription?.is_trial && (
                      <Link href={`/maktb/crm/contacts/${formik.values.tenant_contact.id}`} underline="hover">
                        <Tooltip title={translate('contact.navigateToContact')}>
                          <IconButton variant="outlined" color="secondary">
                            <Iconify icon="icon-park-outline:share" />
                          </IconButton>
                        </Tooltip>
                      </Link>
                    )}
                  </Box>
                }
              />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="client"
                      label={translate('deal.clientName')}
                      value={formik.values.tenant_contact.name}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <TextField
                      {...commonFieldsProps}
                      name="mobile_number"
                      label={translate('deal.clientMobileNumber')}
                      value={formik.values.tenant_contact.mobile_number}
                      disabled
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} lg={4} />
                  {formik?.values?.property_order && (
                    <>
                      <Grid item xs={12} lg={4}>
                        <TextField
                          {...commonFieldsProps}
                          name="purpose"
                          label={translate('deal.purpose')}
                          value={translate(
                            `deal.${
                              formik?.values?.property_order?.property.purpose === 'sell'
                                ? 'buy'
                                : formik?.values?.property_order?.property.purpose
                            }`
                          )}
                          disabled
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          {...commonFieldsProps}
                          name="category"
                          label={translate('deal.category')}
                          value={translate(`deal.${formik?.values?.property_order?.property.category}`)}
                          disabled
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          {...commonFieldsProps}
                          name="type"
                          label={translate('deal.listingType')}
                          value={translate(`deal.${formik?.values?.property_order?.property.type}`)}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {formik?.values?.property_order && (
            <Grid item xs={12} lg={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" paragraph>
                    {translate('order.propertyOrder')}
                  </Typography>
                  {formik?.values?.property_order?.property && (
                    <PropertyCard
                      showLink={
                        !activeMaktbSubscription?.is_trial &&
                        !(
                          workspace?.role_id === TENANT_ROLES.companyMember &&
                          userInfo?.id !== formik?.values?.property_order?.property?.created_by_id
                        )
                      }
                      property={formik?.values?.property_order?.property}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} lg={formik?.values?.property_order ? 6 : 12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph>
                  {translate('order.type')}
                </Typography>
                <Box>
                  <Grid item xs={12}>
                    <TextField
                      {...commonFieldsProps}
                      name="note"
                      value={languageCode === 'ar' ? formik?.values?.description_ar : formik?.values?.description_en}
                      disabled
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                  {translate('request.statusTitle')}
                </Typography>
                <TextField
                  {...commonFieldsProps}
                  name="type"
                  label={translate('request.status')}
                  value={
                    formik?.values?.status &&
                    `${translate(`order.${formik?.values?.status}`)}${
                      formik?.values?.service_order?.is_free ? ` (${translate('service.isFree')})` : ''
                    }`
                  }
                  disabled
                  fullWidth
                />

                {!formik?.values?.service_order?.is_free && (
                  <>
                    <Typography variant="h6" paragraph sx={{ mb: 2, mt: 2 }}>
                      {translate('order.paymentMethod')}
                    </Typography>

                    {formik.values.is_online_payment && (
                      <TextField
                        {...commonFieldsProps}
                        name="type"
                        label={translate('order.paymentMethod')}
                        value={formik.values.status && translate(`order.paymentMethods.online`)}
                        disabled
                        fullWidth
                      />
                    )}

                    {formik.values.is_bank_transfer && (
                      <TextField
                        {...commonFieldsProps}
                        name="type"
                        label={translate('order.paymentMethod')}
                        value={formik.values.status && translate(`order.paymentMethods.bankTransfer`)}
                        disabled
                        fullWidth
                      />
                    )}

                    {formik.values.is_cash && (
                      <TextField
                        {...commonFieldsProps}
                        name="type"
                        label={translate('order.paymentMethod')}
                        value={formik.values.status && translate(`order.paymentMethods.cash`)}
                        disabled
                        fullWidth
                      />
                    )}

                    <Typography variant="h6" paragraph sx={{ mb: 2, mt: 2 }}>
                      {`${translate('unit.downPaymentPrice')}: ${parseFloat(
                        formik?.values?.total_amount_without_tax || 0
                      ).toFixed(2)} ${translate('SAR')}`}
                    </Typography>
                    <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                      {`${translate('unit.downPaymentPriceVAT')}: ${parseFloat(
                        formik?.values?.total_amount_with_tax - formik?.values?.total_amount_without_tax
                      ).toFixed(2)} ${translate('SAR')}`}
                    </Typography>
                    <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                      {`${translate('unit.downPaymentTotalWithVAT')}: ${parseFloat(
                        formik?.values?.total_amount_with_tax
                      ).toFixed(2)} ${translate('SAR')}`}
                    </Typography>

                    {formik.values.status === 'pending_payment' && (
                      <Button variant={'outlined'} fullWidth href={paymentLink} download target="_blanck">
                        {translate('order.paymentLink')}
                      </Button>
                    )}

                    {formik.values.status === 'completed' && (
                      <Button
                        variant={'outlined'}
                        fullWidth
                        href={`${formik?.values?.invoice_url}&download=true`}
                        download
                        target="_blanck"
                      >
                        {translate('order.downloadInvoice')}
                      </Button>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OrderForm;
