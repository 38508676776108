/* eslint-disable no-useless-return */
// @mui
import ReportIcon from '@mui/icons-material/Report';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// hooks
import * as Yup from 'yup';

import ApplePayIcon from '../../../assets/apple-pay-icon.svg';
import MadaIcon from '../../../assets/mada-icon.svg';
import MastercardIcon from '../../../assets/mastercard-icon.svg';
import VVisaIcon from '../../../assets/vvisa-icon.svg';
import Datetime from '../../../components/Datetime';
// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { TicketServices } from '../../../models/tickets/services';
import ActivationDocumentSelector from './ActivationDocumentSelector';

// ----------------------------------------------------------------------

export default function Activations() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const [initialized, setInitialized] = useState(false);
  const [errorFields, setErrorFields] = useState([]);

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const validationSchema = Yup.object({
    business_type: Yup.string().required('Business type is required'),
    ownerId: Yup.string().required('Owner ID is required'),
    freelanceCert: Yup.string().when('business_type', {
      is: 'individual',
      then: Yup.string().required('Freelance certificate is required'),
      otherwise: Yup.string().nullable(),
    }),
    ownersIds: Yup.array().when('business_type', {
      is: 'company',
      then: Yup.array().required('Owners IDs are required'),
      otherwise: Yup.array().nullable(),
    }),
    faalCert: Yup.string().required('FAAL certificate is required'),
    motIwaaCert: Yup.string().required('MOT IWAA certificate is required'),
    aoc: Yup.string().when('business_type', {
      is: 'company',
      then: Yup.string().required('AOC (Certificate of Authorization) is required'),
      otherwise: Yup.string().nullable(),
    }),
    crCert: Yup.string().when('business_type', {
      is: 'company',
      then: Yup.string().required('CR certificate is required'),
      otherwise: Yup.string().nullable(),
    }),
    nationalAddress: Yup.string().required('National address is required'),
    bankCert: Yup.string().required('Bank certificate is required'),
  });

  const formik = useFormik({
    initialValues: {
      business_type: '',
      ownerId: '',
      freelanceCert: '',
      faalCert: '',
      aoc: '',
      nationalAddress: '',
      ownersIds: [],
      motIwaaCert: '',
      crCert: '',
      bankCert: '',
      status: 'draft',
      notes: [],
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const response = await new TicketServices().submitNuzulPayTicket(values);
      mapToFormikFromResponse(response);
    },
  });

  const mapToFormikFromResponse = (response) => {
    if (!response?.data?.data) {
      return;
    }

    formik.setFieldValue('business_type', response.data.data.business_type);
    formik.setFieldValue('ownerId', response.data.data.owner_id);
    formik.setFieldValue('freelanceCert', response.data.data.freelance_cert);
    formik.setFieldValue('faalCert', response.data.data.faal_cert);
    formik.setFieldValue('aoc', response.data.data.aoc);
    formik.setFieldValue('nationalAddress', response.data.data.national_address);
    formik.setFieldValue(
      'ownersIds',
      (response.data.data.owners_ids || []).filter((x) => x)
    );
    formik.setFieldValue('motIwaaCert', response.data.data.mot_iwaa_cert);
    formik.setFieldValue('crCert', response.data.data.cr_cert);
    formik.setFieldValue('bankCert', response.data.data.bank_cert);

    formik.setFieldValue('status', response.data.status);
    formik.setFieldValue('notes', response.data.notes);
    formik.setFieldValue('lastSubmittedAt', response.data.last_submitted_at);

    setInitialized(true);

    const latestNoteErrors = get(response.data.notes, `[${response.data.notes.length - 1}].meta.errors`, []);
    setErrorFields(latestNoteErrors);
    formik.validateForm();
  };

  const debouncedUpdate = useDebouncedCallback(async () => {
    if (['submitted', 'approved', 'done'].includes(formik.values.status)) {
      return;
    }
    await new TicketServices().updateNuzulPayTicket(formik.values);
  }, 100);

  useEffect(async () => {
    const response = await new TicketServices().getNuzulPayTicket();

    mapToFormikFromResponse(response);
  }, []);

  useEffect(async () => {
    if (!initialized) {
      return;
    }

    debouncedUpdate();
  }, [formik.values]);

  return (
    <Page title={translate('dashboard')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" gutterBottom>
          {translate('pay.activation.title')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Grid container justifyContent={'center'} mt={3}>
            <Grid item xs={12} md={12} mb={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    {translate('pay.activation.rates')}
                  </Typography>

                  <Grid container spacing={2} alignItems="end" sx={{ marginBottom: 1 }}>
                    <Grid display={'flex'} item xs={3}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <img src={VVisaIcon} alt="Visa" style={{ width: 40, height: 20 }} />
                      </Box>
                      <Typography variant="body1">Visa</Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body1">2.7% + 1 {translate('SAR')}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} alignItems="end" sx={{ marginBottom: 1 }}>
                    <Grid display={'flex'} item xs={3}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <img src={MastercardIcon} alt="Mastercard" style={{ width: 40, height: 20 }} />
                      </Box>
                      <Typography variant="body1">Mastercard</Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body1">2.7% + 1 {translate('SAR')}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
                    <Grid item xs={3} display={'flex'}>
                      <img src={MadaIcon} alt="Mada" style={{ width: 40, height: 20 }} />
                      <Typography variant="body1">Mada</Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body1">1% + 1 {translate('SAR')}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2 }}>
                    <Grid item xs={3} display={'flex'}>
                      <img src={ApplePayIcon} alt="Apple Pay" style={{ width: 40, height: 20 }} />
                      <Typography variant="body1">Apple Pay</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body1">{translate('pay.activation.applePayRate')}</Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="caption" sx={{ color: 'grey.500' }}>
                    {translate('pay.activation.vat')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              sx={{
                ...(['submitted', 'approved', 'done'].includes(formik.values.status) ? { display: 'none' } : {}),
              }}
              item
              xs={12}
              md={12}
              mb={3}
            >
              <Card>
                <CardContent>
                  <Typography variant="h5" sx={{ marginBottom: 2 }}>
                    {translate('pay.activation.requirements')}
                  </Typography>

                  <Grid container alignItems="start" sx={{ marginBottom: 1 }}>
                    <Grid item xs={6} sx={{}}>
                      <Typography variant="h6">{translate('pay.activation.individual')}</Typography>
                      <p>{translate('pay.activation.ownerId')}</p>
                      <p>{translate('pay.activation.freelanceCert')}</p>
                      <p>{translate('pay.activation.faalCert')}</p>
                      <p>{translate('pay.activation.motIwaaCert')}</p>
                      <p>{translate('pay.activation.nationalAddress')}</p>
                      <p>{translate('pay.activation.bankCert')}</p>
                    </Grid>

                    <Grid item xs={6} sx={{}}>
                      <Typography variant="h6">{translate('pay.activation.institutionOrCompany')}</Typography>
                      <p>{translate('pay.activation.ownerId')}</p>
                      <p>{translate('pay.activation.ownersIds')}</p>
                      <p>{translate('pay.activation.aoc')}</p>
                      <p>{translate('pay.activation.crCert')}</p>
                      <p>{translate('pay.activation.faalCert')}</p>
                      <p>{translate('pay.activation.motIwaaCert')}</p>
                      <p>{translate('pay.activation.nationalAddress')}</p>
                      <p>{translate('pay.activation.bankCert')}</p>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              sx={{
                ...(['submitted', 'approved', 'done'].includes(formik.values.status) ? { display: 'none' } : {}),
              }}
              item
              xs={12}
              md={12}
            >
              <Card>
                <CardContent>
                  <Typography variant="h4" paragraph sx={{ mb: 0 }}>
                    {translate('pay.activation.filesSubmission')}
                  </Typography>
                  <Typography variant="caption" paragraph sx={{ color: 'grey.500' }}>
                    {translate('pay.activation.filesSubmissionCaption')}
                  </Typography>

                  <FormControl fullWidth sx={{ p: 1 }}>
                    <RadioGroup
                      name="business_type"
                      defaultValue="individual"
                      row
                      sx={{ pl: 1 }}
                      value={formik.values.business_type}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        label={translate('pay.activation.individual')}
                        onChange={formik.handleChange}
                        control={<Radio value="individual" color="tertiary" />}
                      />
                      <FormControlLabel
                        label={translate('pay.activation.institutionCompany')}
                        onChange={formik.handleChange}
                        control={<Radio value="company" color="tertiary" />}
                      />
                    </RadioGroup>
                  </FormControl>

                  <Box>
                    {formik.values.notes
                      .filter((_note, index) => index === formik.values.notes.length - 1)
                      .map((note) => (
                        <Card sx={{ backgroundColor: '#FFE9D5' }} key={note.id}>
                          <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ReportIcon color="error" />
                              <Typography variant="subtitle2" color={theme.palette.error.dark} sx={{ mx: 1 }}>
                                {note.content}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Box>

                  {formik.values.business_type && (
                    <Grid container spacing={10}>
                      <Grid item xs={12} lg={6}>
                        <ActivationDocumentSelector
                          formik={formik}
                          fieldName="ownerId"
                          documentLabel={translate('pay.activation.ownerId')}
                          error={errorFields.includes('ownerId')}
                        />

                        <ActivationDocumentSelector
                          formik={formik}
                          fieldName="faalCert"
                          documentLabel={translate('pay.activation.faalCert')}
                          error={errorFields.includes('faalCert')}
                        />

                        {formik.values.business_type === 'company' && (
                          <ActivationDocumentSelector
                            formik={formik}
                            fieldName="aoc"
                            documentLabel={translate('pay.activation.aoc')}
                            error={errorFields.includes('aoc')}
                          />
                        )}

                        <ActivationDocumentSelector
                          formik={formik}
                          fieldName="nationalAddress"
                          documentLabel={translate('pay.activation.nationalAddress')}
                          error={errorFields.includes('nationalAddress')}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        {formik.values.business_type === 'individual' && (
                          <ActivationDocumentSelector
                            formik={formik}
                            fieldName="freelanceCert"
                            documentLabel={translate('pay.activation.freelanceCert')}
                            error={errorFields.includes('freelanceCert')}
                          />
                        )}

                        {formik.values.business_type === 'company' && (
                          <ActivationDocumentSelector
                            formik={formik}
                            fieldName="ownersIds"
                            documentLabel={translate('pay.activation.ownersIds')}
                            error={errorFields.includes('ownersIds')}
                            multiple
                          />
                        )}

                        <ActivationDocumentSelector
                          formik={formik}
                          fieldName="motIwaaCert"
                          documentLabel={translate('pay.activation.motIwaaCert')}
                          error={errorFields.includes('motIwaaCert')}
                        />

                        {formik.values.business_type === 'company' && (
                          <ActivationDocumentSelector
                            formik={formik}
                            fieldName="crCert"
                            documentLabel={translate('pay.activation.crCert')}
                            error={errorFields.includes('crCert')}
                          />
                        )}

                        <ActivationDocumentSelector
                          formik={formik}
                          fieldName="bankCert"
                          documentLabel={translate('pay.activation.bankCert')}
                          error={errorFields.includes('bankCert')}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container alignItems={'center'} justifyContent={'end'} mt={5}>
                    <Button
                      disabled={!formik.isValid}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ color: 'white' }}
                    >
                      {translate('pay.activation.submit')}
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              mb={3}
              sx={{
                ...(formik.values.status === 'submitted' ? {} : { display: 'none' }),
              }}
            >
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    height="100%"
                    sx={{ textAlign: 'center', minHeight: '300px' }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      {translate('pay.activation.thankYou')}
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      {translate('pay.activation.reviewMsg')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 0 }}>
                      <Typography variant="caption" paragraph sx={{ color: 'grey.500' }}>
                        {translate('pay.activation.submissionDate')}
                      </Typography>
                      {formik.values.lastSubmittedAt && (
                        <Datetime
                          minWidth="100px"
                          date={formik.values.lastSubmittedAt}
                          chipStyles={{
                            border: 'none',
                            color: theme.palette.grey[600],
                            p: 0,
                            m: 0,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
}
