import { createAction } from '@reduxjs/toolkit';

export const getPropertiesRequest = createAction('GET_PROPERTIES_REQUEST');
export const getPropertiesSuccess = createAction('GET_PROPERTIES_SUCCESS');
export const getPropertiesFailure = createAction('GET_PROPERTIES_FAILURE');

export const editPropertyRequest = createAction('EDIT_PROPERTY_REQUEST');
export const editPropertySuccess = createAction('EDIT_PROPERTY_SUCCESS');
export const editPropertyFailure = createAction('EDIT_PROPERTY_FAILURE');

export const searchPropertiesRequest = createAction('SEARCH_PROPERTIES_REQUEST');
export const searchPropertiesSuccess = createAction('SEARCH_PROPERTIES_SUCCESS');
export const searchPropertiesFailure = createAction('SEARCH_PROPERTIES_FAILURE');

export const filterPropertiesRequest = createAction('FILTER_PROPERTIES_REQUEST');
export const filterPropertiesSuccess = createAction('FILTER_PROPERTIES_SUCCESS');
export const filterPropertiesFailure = createAction('FILTER_PROPERTIES_FAILURE');

export const createPropertyRequest = createAction('CREATE_PROPERTY_REQUEST');
export const createPropertySuccess = createAction('CREATE_PROPERTY_SUCCESS');
export const createPropertyFailure = createAction('CREATE_PROPERTY_FAILURE');

export const duplicatePropertyRequest = createAction('DUPLICATE_PROPERTY_REQUEST');
export const duplicatePropertySuccess = createAction('DUPLICATE_PROPERTY_SUCCESS');
export const duplicatePropertyFailure = createAction('DUPLICATE_PROPERTY_FAILURE');

export const getPropertyRequest = createAction('GET_PROPERTY_REQUEST');
export const getPropertySuccess = createAction('GET_PROPERTY_SUCCESS');
export const getPropertyFailure = createAction('GET_PROPERTY_FAILURE');

export const removeUrlsRequest = createAction('REMOVE_URLS_REQUEST');
export const removeUrlsSuccess = createAction('REMOVE_URLS_SUCCESS');
export const removeUrlsFailure = createAction('REMOVE_URLS_FAILURE');

export const toggleArchivePropertyRequest = createAction('TOGGLE_ARCHIVE_PROPERTY_REQUEST');
export const toggleArchivePropertySuccess = createAction('TOGGLE_ARCHIVE_PROPERTY_SUCCESS');
export const toggleArchivePropertyFailure = createAction('TOGGLE_ARCHIVE_PROPERTY_FAILURE');

export const exportPropertiesAsSheetRequest = createAction('EXPORT_PROPERTIES_AS_SHEET_REQUEST');
export const exportPropertiesAsSheetSuccess = createAction('EXPORT_PROPERTIES_AS_SHEET_SUCCESS');
export const exportPropertiesAsSheetFailure = createAction('EXPORT_PROPERTIES_AS_SHEET_FAILURE');

export const exportPropertyAsPdfRequest = createAction('EXPORT_PROPERTY_AS_PDF_REQUEST');
export const exportPropertyAsPdfSuccess = createAction('EXPORT_PROPERTY_AS_PDF_SUCCESS');
export const exportPropertyAsPdfFailure = createAction('EXPORT_PROPERTY_AS_PDF_FAILURE');

export const getAmenitiesRequest = createAction('GET_AMENITIES_REQUEST');
export const getAmenitiesSuccess = createAction('GET_AMENITIES_SUCCESS');
export const getAmenitiesFailure = createAction('GET_AMENITIES_FAILURE');
