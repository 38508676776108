// components
import Chart, { useChart } from '../../components/chart';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function ChartDonut({ series, dir }) {
  const { translate } = useLocales();
  const chartOptions = useChart({
    labels: [
      translate('deal.stages.new'),
      translate('deal.stages.contacted'),
      translate('deal.stages.visit'),
      translate('deal.stages.negotiation'),
      translate('deal.stages.won'),
      translate('deal.stages.lost'),
    ],
    colors: ['#FFC72C', '#9C27B0', '#FF5D33', '#5D2CFF', '#12C2CF', '#9E005D'],
    stroke: {
      show: false,
    },
    legend: {
      horizontalAlign: 'center',
      fontSize: '14px',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: { label: translate('total') },
          },
        },
      },
    },
  });

  return (
    <Chart
      type="donut"
      series={series}
      options={chartOptions}
      width={400}
      style={{
        direction: dir,
      }}
    />
  );
}
