/* eslint-disable no-nested-ternary */

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import FileSelector from '../../../components/FileSelector';
import { Loader } from '../../../components/Loader';
import useLocales from '../../../hooks/useLocales';
import UtilsServices from '../../../models/utils/services';

const ActivationDocumentSelector = ({ formik, fieldName, documentLabel, error = false, multiple = false }) => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();

  const [isAttachingFiles, setIsAttachingFiles] = useState(false);

  const handleAttachmentUpload = (files) => {
    setIsAttachingFiles(true);
    setLoading(true);
    UtilsServices.uploadFiles(files)
      .then((response) => {
        setIsAttachingFiles(false);
        const newAttachments = response.data.map((file) => file.url);
        if (multiple) {
          const updatedAttachments = [...(formik.values[fieldName] || []), ...newAttachments];
          formik.setFieldValue(fieldName, updatedAttachments);
        } else {
          formik.setFieldValue(fieldName, newAttachments[0] || '');
        }
        setLoading(false);
      })
      .catch((error) => {
        setIsAttachingFiles(false);
        setLoading(false);
        console.error('Error during upload:', error);
      });
  };

  const handleDeleteAttachment = (index) => {
    if (multiple) {
      const updatedAttachments = [...(formik.values[fieldName] || [])];
      updatedAttachments.splice(index, 1);
      formik.setFieldValue(fieldName, updatedAttachments);
    } else {
      formik.setFieldValue(fieldName, '');
    }
  };

  const renderFiles = () => {
    if (multiple) {
      return (
        <Grid container spacing={2}>
          {(formik.values[fieldName] || []).map((fileUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  position: 'relative',
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                  overflow: 'hidden',
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    backgroundColor: 'rgba(22, 28, 36, 0.72)',
                    color: '#fff',
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteAttachment(index);
                  }}
                  size="small"
                >
                  <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
                </IconButton>
                <iframe
                  title={`pdf-${index}`}
                  src={`${fileUrl}#toolbar=0&navpanes=0`}
                  width="100%"
                  height="200px"
                  style={{ border: 'none' }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      );
    }

    return formik.values[fieldName] ? (
      <Box
        sx={{
          position: 'relative',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            backgroundColor: 'rgba(22, 28, 36, 0.72)',
            color: '#fff',
            zIndex: 1,
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteAttachment();
          }}
          size="small"
        >
          <CloseRoundedIcon sx={{ height: 16, width: 16 }} />
        </IconButton>
        <iframe
          title="pdf"
          src={`${formik.values[fieldName]}#toolbar=0&navpanes=0`}
          width="100%"
          height="200px"
          style={{ border: 'none' }}
        />
      </Box>
    ) : null;
  };

  return (
    <FileSelector
      name={fieldName}
      error={formik.touched[fieldName] && formik.errors[fieldName]}
      value={formik.values[fieldName]}
      onFiles={handleAttachmentUpload}
      multiple={multiple}
      accept=".pdf"
    >
      {(isDraggingOver, handleBrowse) => (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" paragraph sx={{ mb: 1, mt: 2 }}>
            {documentLabel}
          </Typography>
          <Box
            sx={{
              cursor: 'pointer',
              minHeight: multiple ? ((formik.values[fieldName] || []).length === 0 ? 175 : 'unset') : 175,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              border: `1px dashed ${error ? theme.palette.error.light : '#BCBCBC'}`,
              p: 2,
              backgroundColor: error ? alpha(theme.palette.error.main, 0.08) : 'transparent',
            }}
            onClick={handleBrowse}
          >
            {isLoading && <Loader />}
            {multiple ? (
              renderFiles()
            ) : formik.values[fieldName] ? (
              renderFiles()
            ) : (
              <Box textAlign={'center'}>
                {!isLoading && (
                  <>
                    <Typography variant="subtitle2" color={error ? theme.palette.error.main : 'inherit'}>
                      {isDraggingOver ? 'Drop your PDF' : translate('pay.activation.selectFile')}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
                      {translate('pay.activation.selectFileCaption')}
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Typography variant="caption" sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
            {translate('pay.activation.fileSize')}
          </Typography>
        </Box>
      )}
    </FileSelector>
  );
};

export default ActivationDocumentSelector;
