import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import PropertiesServices from './services';

export function* getPropertiesSaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.getProperties, payload);
    yield put(actions.getPropertiesSuccess(response.data));
  } catch (err) {
    yield put(actions.getPropertiesFailure(err));
  }
}

export function* exportPropertiesAsSheetSaga({ payload }) {
  const payloadCallback = payload.callback;

  delete payload.callback;
  try {
    const response = yield call(PropertiesServices.getProperties, {
      export: true,
      ...payload,
    });
    yield put(actions.exportPropertiesAsSheetSuccess(response.data));
    payloadCallback?.('success', response.data);
  } catch (err) {
    yield put(actions.exportPropertiesAsSheetFailure(err));
    payloadCallback?.('error', err.response.data);
  }
}

export function* searchPropertiesSaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.searchProperties, payload);
    yield put(actions.searchPropertiesSuccess(response.data));
  } catch (err) {
    yield put(actions.searchPropertiesFailure(err));
  }
}

export function* filterPropertiesSaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.filterProperties, payload);
    yield put(actions.filterPropertiesSuccess(response.data));
  } catch (err) {
    yield put(actions.filterPropertiesFailure(err));
  }
}

export function* getPropertySaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.getProperty, payload);
    yield put(actions.getPropertySuccess(response.data));
  } catch (err) {
    yield put(actions.getPropertyFailure(err));
  }
}

export function* exportPropertyPdfSaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.exportPropertyAsPdf, payload.id);
    yield put(actions.exportPropertyAsPdfSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.exportPropertyAsPdfFailure(err));
    payload?.callback?.('error', err);
  }
}

export function* createPropertySaga({ payload }) {
  const { callback, ...values } = payload;

  try {
    const response = yield call(PropertiesServices.createProperty, values);
    yield put(actions.createPropertySuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createPropertyFailure(err));
    callback?.('error', err);
  }
}

export function* duplicatePropertySaga({ payload }) {
  const { callback, ...values } = payload;

  try {
    const response = yield call(PropertiesServices.duplicateProperty, values);
    yield put(actions.duplicatePropertySuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.duplicatePropertyFailure(err));
    callback?.('error', err);
  }
}

export function* removeUrlsSaga({ payload }) {
  try {
    const response = yield call(() => PropertiesServices.removeImages(payload.id, payload.urls));
    yield put(actions.removeUrlsSuccess(response.data));
    payload?.callback?.('success', response.data);
  } catch (err) {
    yield put(actions.removeUrlsFailure(err));
    payload?.callback?.('error', err);
  }
}

export function* editPropertySaga({ payload }) {
  const { id, callback, ...values } = payload;

  try {
    const response = yield call(() => PropertiesServices.editProperty(id, values));
    yield put(actions.editPropertySuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.editPropertyFailure(err));
    callback?.('error', err);
  }
}

export function* togglePropertyArchiveSaga({ payload }) {
  const { id, callback } = payload;

  try {
    const response = yield call(() => PropertiesServices.togglePropertyAchieve(id));
    yield put(actions.toggleArchivePropertySuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.toggleArchivePropertyFailure(err));
    callback?.('error', err);
  }
}

export function* getAmenitiesSaga({ payload }) {
  try {
    const response = yield call(PropertiesServices.getAmenities, payload);
    yield put(actions.getAmenitiesSuccess(response.data));
  } catch (err) {
    yield put(actions.getAmenitiesFailure(err));
  }
}

export function* propertiesSaga() {
  yield all([
    takeLatest(actions.getPropertiesRequest, getPropertiesSaga),
    takeLatest(actions.searchPropertiesRequest, searchPropertiesSaga),
    takeLatest(actions.exportPropertiesAsSheetRequest, exportPropertiesAsSheetSaga),
    takeLatest(actions.filterPropertiesRequest, filterPropertiesSaga),
    takeLatest(actions.createPropertyRequest, createPropertySaga),
    takeLatest(actions.duplicatePropertyRequest, duplicatePropertySaga),
    takeLatest(actions.getPropertyRequest, getPropertySaga),
    takeLatest(actions.exportPropertyAsPdfRequest, exportPropertyPdfSaga),
    takeLatest(actions.removeUrlsRequest, removeUrlsSaga),
    takeLatest(actions.editPropertyRequest, editPropertySaga),
    takeLatest(actions.toggleArchivePropertyRequest, togglePropertyArchiveSaga),
    takeLatest(actions.getAmenitiesRequest, getAmenitiesSaga),
  ]);
}
