import 'react-toastify/dist/ReactToastify.css';

// @mui
import { Box, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthButton from '../../../components/buttons/AuthButton';
import { Loader } from '../../../components/Loader';
import MobilePhoneInput from '../../../components/MobilePhoneInput';
import { isProduction } from '../../../config';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import AuthBaseLayout from '../../../layouts/AuthBaseLayout';
// store
import * as authActions from '../../../models/auth/actions';
import * as userActions from '../../../models/user/actions';
import { disableButton } from '../Auth.utils';
import { loginSchema } from '../Auth.validationSchemas';
import { Password } from '../components';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const urlParams = new URLSearchParams(window.location.search);
  const mobileNumber = urlParams.get('m');
  const password = urlParams.get('p');

  const isMasterLogin = !isProduction && mobileNumber && password;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // clear local storage when user visit /login page
    if (token && !isMasterLogin) {
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('token');
      localStorage.removeItem('user_role_id');
      localStorage.removeItem('workspaces');
      localStorage.removeItem('tenant_url');
      localStorage.removeItem('tenant_index');
      window.location.reload();
    }
  }, []);

  const handleNavigate = (type) => {
    setLoading(false);

    if (type === 'success') {
      window.open('/', '_self');
    }
  };

  const handleCallback = (type, res) => {
    if (type === 'success') {
      dispatch(userActions.getUserInformationRequest({ callback: handleNavigate }));
    } else {
      setLoading(false);

      if (res.status === 422) {
        const validationErrors = res.data.errors;
        const errorMessages = Object.entries(validationErrors).map(
          ([field, messages]) => `${translate(`loginScreen.${field}`)}: ${translate(`loginScreen.${messages}`)}`
        );
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      }

      if (res.status === 401) {
        const errorMessage = res.data.message;
        toast.error(translate(`loginScreen.${errorMessage}`));
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
      code: '+966',
    },
    loginSchema,
    onSubmit: (values) => {
      const { code, phone, password } = values;
      setLoading(true);
      dispatch(
        authActions.loginRequest({ mobileNumber: `${code.substring(1)}${phone}`, password, callback: handleCallback })
      );
    },
  });

  const isButtonDisabled = disableButton(['phone', 'password'], formik) || loading;

  useEffect(() => {
    if (isMasterLogin) {
      formik.setFieldValue('phone', mobileNumber);
      formik.setFieldValue('password', password);
      formik.handleSubmit();
    }
  }, [isMasterLogin]);

  return (
    <AuthBaseLayout heading={translate('auth.login.heading')} subtitle={translate('auth.login.subtitle')}>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <MobilePhoneInput
            onChange={(phone) => {
              formik.setFieldValue('phone', phone || '');
            }}
          />
          <Password formik={formik} />
          <Link
            component="button"
            variant="caption"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              navigate('/verify-mobile');
            }}
            sx={{ textDecoration: 'underline', color: '#0066FF', marginTop: '10px' }}
          >
            {translate('auth.login.forgotPassword')}
          </Link>

          <AuthButton type="submit" text={translate('auth.login.loginButton')} isDisabled={isButtonDisabled} />

          {loading && <Loader />}

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ marginTop: '30px' }}
          >
            <Typography variant="caption">{translate('auth.login.noAccount')}</Typography>
            <Link
              component="button"
              variant="caption"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                navigate('/registration');
              }}
              sx={{ textDecoration: 'underline', color: '#0066FF', marginLeft: '10px' }}
            >
              {translate('auth.login.signUp')}
            </Link>
          </Box>
        </form>
      </div>
    </AuthBaseLayout>
  );
};

export default Login;
