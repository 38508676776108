import { Box, Input } from '@mui/material';
import { useRef, useState } from 'react';

const FileSelector = ({ name, value, error, children, onDragOver, onFiles, multiple = false, accept = 'image/*' }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
    if (onDragOver) {
      onDragOver(e);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      onFiles(files);
    }
  };

  const handleUpload = async (e) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles && uploadedFiles.length > 0) {
      await onFiles(uploadedFiles);
    }
  };

  return (
    <Box onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} sx={{ width: '100%' }}>
      <Input
        inputProps={{ multiple, accept }}
        inputRef={fileInputRef}
        value=""
        name={name}
        error={!!error}
        type="file"
        onChange={handleUpload}
        style={{ display: 'none' }}
        aria-hidden
        hidden
      />
      {children(isDraggingOver, handleBrowse)}
    </Box>
  );
};

export default FileSelector;
