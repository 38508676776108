// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  '/a month': '/Monthly',
  '/per user a month': '/per user a month',
  APIFeatures: 'API features',
  'Activate your Nuzul Business': 'Activate your Nuzul Business 🚀',
  Annually: 'Annually',
  Apr: 'Apr',
  Aug: 'Aug',
  'Bank transfer': 'Bank transfer',
  'Billing period': 'Billing period',
  'Buy now': 'Buy now',
  'By activating trial, you agree to the': 'By activating trial, you agree to the',

  'By proceeding, you agree to the': 'By proceeding, you agree to the',
  CRM: 'Clients relations',
  City: 'City',
  'Coming soon!': 'Coming soon!',
  Country: 'Country',
  Dec: 'Dec',
  Discount: 'Discount',
  District: 'District',
  Districts: 'Districts',
  'Edited stage successfully': 'Edited stage successfully',
  Feb: 'Feb',
  Financials: 'Financials',
  'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.':
    'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.',
  GRM: 'Guest relations',
  Jan: 'Jan',
  Jul: 'Jul',
  Jun: 'Jun',
  'Load More': 'Load More',
  Mar: 'Mar',
  May: 'May',
  'Member Insights Dashboard': 'Member Insights Dashboard',
  Monthly: 'Monthly',
  'Next billing will be': 'Next billing will be',
  Notifications: 'Notifications',
  Nov: 'Nov',
  'Nuzul Business': 'Nuzul Business',
  Oct: 'Oct',
  'Online payment': 'Online payment',
  Operations: 'Operations',
  'Order Information': 'Order Information',
  'Please enter a valid number format (e.g., 25000.34)': 'Please enter a valid number format (e.g., 25000.34)',
  Quarterly: 'Quarterly',
  SAR: 'SAR',
  'SAR 199': 'SAR 199',
  'SMS Campaigns': 'SMS Campaigns (Soon)',
  'Saudi Riyals': 'Saudi Riyals',
  'Save 20% when you pay annually': 'Save 20% when you pay annually',
  Sep: 'Sep',
  'Start now': 'Start now',
  Subtotal: 'Subtotal',
  'Successfully updated': 'Successfully updated',
  'Terms and Conditions': 'Terms and Conditions',
  Total: 'Total',
  'Upload a logo': 'Upload a logo',
  Users: 'Users',
  VAT: 'VAT',
  'View All': 'View All',
  'Visit your website': 'Visit your website',
  Workspaces: 'Account',
  'You have reached the limit of properties for your current plan.':
    'You have reached the limit of properties for your current plan.',
  'Your trial will expire on ': 'Your trial will expire on ',
  addContactScreen: {
    'Contact already exists.': 'Contact already exists.',
    successCreation: 'Client created successfully',
    successUpdate: 'Client information updated successfully',
  },
  addListingsModal: {
    trialMessage: 'Add unlimited number of units!',
  },
  attachFile: 'Attach file',
  auth: {
    completeAccount: {
      businessName: 'Company name',
      byProceeding: 'By proceeding, you agree to the',
      createAccountBtn: 'Create account',
      email: 'Email',
      name: 'Personal Name',
      subtitle: 'Account Information',
      termsCondition: 'Terms and Conditions',
    },
    forgotPassword: {
      heading: 'Forgot your password?',
      subtitle: 'Please enter the mobile number associated with your account.',
    },
    login: {
      countryCode: 'Country code',
      forgotPassword: 'Forgot Password?',
      heading: 'Log in to Nuzul',
      loginButton: 'Log in',
      mobileExample: 'Example: 501234567',
      mobileNumber: 'Mobile number',
      noAccount: "Don't have an account?",
      password: 'Password',
      signUp: 'Sign up',
      subtitle: 'Welcome back!',
    },
    passwordReset: {
      changePasswordBtn: 'Change password',
      heading: 'Password reset',
      subtitle: 'Please enter the new password',
      success: 'Password updated successfully!',
    },
    registration: {
      alreadyAccount: 'Already have an account?',
      heading: 'Activate your digital transformation now 🚀',
      login: 'Login',
      subtitle: '',
      verifyMobile: 'Verify Mobile',
    },
    verifyCode: {
      back: 'Back',
      noVerificationCode: "Didn't receive the code?",
      resendCode: 'Resend code',
      subtitle: 'Please enter the 4-digit code to {{mobileNumber}}.',
      verificationCode: 'Verification code',
      verifyCodeBtn: 'Verify Code',
    },
  },
  bestSeller: 'Best seller',
  cancel: 'Cancel',
  cart: {
    'Coupon applied successfully': 'Coupon applied successfully',
    'Invalid coupon code': 'Invalid coupon code',
    features: 'Features',
    freePlan: 'Free Plan',
    paidPlan: 'Paid Plan',
  },
  clickToCopy: 'Click to copy',
  clients: 'Clients',
  completeAccountScreen: {
    'The email field is required.': 'The email field is required.',
    'The email has already been taken.': 'The email has already been used before.',
    'The email must be a valid email address.': 'The email must be a valid email address.',
    'The mobile number has already been taken.': 'The mobile number has already been used before.',
    'The name field is required.': 'The name field is required.',
    'The name must not be greater than 50 characters.': 'The name must not be greater than 50 characters.',
    'The password field is required.': 'The password field is required.',
    'The selected token is invalid.': 'Registration session expired. Please restart the registration process.',
    email: 'Email',
    mobile_number: 'Mobile number',
    name: 'Name',
    password: 'Password',
    token: 'Session',
  },
  contact: {
    addContact: 'Create client',
    archiveButton: 'Archive',
    city: 'City (Optional)',
    country: 'Country (Optional)',
    createButton: 'Create client',
    createPageTitle: 'Create contact',
    createdBy: 'Created by',
    editContact: 'Edit',
    email: 'Email (Optional)',
    female: 'Female',
    gender: 'Gender',
    includeArchived: 'Include archived',
    information: 'Client information',
    male: 'Male',
    maqsam: {
      agent: 'Agent',
      duration: 'Duration',
      recording: 'Recording',
      status: 'Status',
      timestamp: 'Date',
    },
    mobileNumber: 'Mobile number',
    name: 'Full name',
    navigateToContact: 'Navigate to this client',
    noDeals: 'No deals for this contact.',
    noMaqsamCalls: 'No call records for this client',
    noOwnedProperties: 'No owned properties for this contact.',
    noPropertyRequests: 'No property requests for this contact.',
    noReservations: 'No reservations for this contact.',
    noUnifonicSMS: 'No Unifonic SMS records',
    note: 'Note',
    ownedProperties: 'Owned Properties',
    property_agent: 'Agent',
    property_broker: 'Broker',
    property_buyer: 'Buyer',
    property_owner: 'Owner',
    property_renter: 'Renter',
    property_representative: 'Representative',
    saveButton: 'Save',
    searchByNumLabel: 'Search by name or mobile',
    searchLabel: 'Search',
    type: 'Client type',
    typeSelect: 'Select whatever apply',
    unarchiveButton: 'Unarchive',
  },
  contacts: 'Clients',
  contracts: 'Contracts (Soon)',
  copied: 'Copied',
  createdAt: 'Created at',
  dashboard: 'Dashboard',
  dateIncluded: 'inclusive',
  deal: {
    amount: 'Amount',
    annually: 'Annually',
    archiveButton: 'Archive',
    building: 'Building',
    building_apartment: 'Apartment in a building',
    buy: 'To buy',
    cancelButton: 'Cancel',
    category: 'Category',
    changeSalesPerson: 'Change Sales Person',
    city: 'City',
    clientMobileNumber: 'Client Mobile Number',
    clientName: 'Client Name',
    commercial: 'Commercial',
    commission: 'Commission',
    compound: 'Compound',
    contactName: 'Contact',
    createButton: 'Create',
    createDealButton: 'Create deal',
    creationTitle: 'Deal information',
    daily: 'Daily',
    duplex: 'Duplex',
    facts: 'Facts',
    farm: 'Farm',
    filterProperties: 'List only matched units',
    hotel: 'Hotel',
    includeArchived: 'Include archived',
    information: 'Deal information',
    istraha: 'Istraha',
    land: 'Land',
    listingType: 'Listing type',
    mansion: 'Mansion',
    matchedPropertiesInfo:
      "We'll choose the best units for your client based on their requests, desired area and districts. Buy or rent? Is it for residential or commercial purposes? What type of property (apartment, land, etc.)?",
    matchedProperty: 'Matched Property',
    maxArea: 'Maximum area',
    maxPrice: 'Maximum price (budget)',
    minArea: 'Minimum area',
    minPrice: 'Minimum price (budget)',
    monthly: 'Monthly',
    noPropertySelected: 'No Property Selected',
    office: 'Office',
    period: 'Period',
    preferredFeatures: 'Preferred Features',
    preferredLocations: 'Preferred locations',
    properties: {
      building: 'Building',
      building_apartment: 'Apartment in a building',
      compound: 'Compound',
      duplex: 'Duplex',
      farm: 'Farm',
      hotel: 'Hotel',
      istraha: 'Istraha',
      land: 'Land',
      mansion: 'Mansion',
      office: 'Office',
      resort: 'Resort',
      showroom: 'Showroom',
      storage: 'Storage',
      store: 'Store',
      tower_apartment: 'Apartment in a tower',
      townhouse: 'Townhouse',
      villa: 'Villa',
      villa_apartment: 'Apartment in a villa',
      villa_floor: 'Floor in a villa',
    },
    property: 'Property',
    purpose: 'Purpose',
    quarterly: 'Quarterly',
    realEstateType: 'Type',
    rent: 'To rent',
    rentPriceAnnually: 'Rental price annually',
    rentPriceMonthly: 'Rental price monthly',
    rentPriceQuarterly: 'Rental price quarterly',
    rentPriceSemiAnnually: 'Rental price semi annually',
    residential: 'Residential',
    resort: 'Resort',
    salesPerson: 'Sales Person',
    saveButton: 'Save',
    searchByNames: 'Search by contact name or sales person',
    searchByUnitNumber: 'Search by unit number',
    searchLabel: 'Search',
    selectProperty: 'Select Property',
    sell: 'To sell',
    semiAnnually: 'Semi-Annually',
    showroom: 'Showroom',
    stage: 'Stage',
    stageDescription: 'Change deal stage',
    stages: {
      contacted: 'Contacted',
      lost: 'Lost',
      negotiation: 'Negotiation',
      new: 'New',
      visit: 'Visit',
      won: 'Won',
    },
    storage: 'Storage',
    store: 'Store',
    tower_apartment: 'Apartment in a tower',
    townhouse: 'Townhouse',
    type: 'Type',
    unarchiveButton: 'Unarchive',
    view: 'View',
    villa: 'Villa',
    villa_apartment: 'Apartment in a villa',
    villa_floor: 'Floor in a villa',
    weekly: 'Weekly',
  },
  deals: 'Deals',
  deposit: {
    bank: 'Bank account',
    dateTime: 'Date and Time',
    day: 'Day',
    fee: 'Deposit Fee',
    total: 'Total',
  },
  deposits: 'Deposits',
  export_as_sheet: 'Export as sheet',
  export_as_sheet_count: 'Export ({{count}}) result as sheet',
  financial: 'Financial',
  for: {
    rent: 'For rent',
    sell: 'For sale',
  },
  freePlan: 'Free Plan',
  from: 'From',
  billing: 'Billing',
  general: 'General',
  growth: 'Growth in ',
  guest: {
    addGuest: 'Add guest',
    createButton: 'Create guest',
    createPageTitle: 'Create guest',
    editGuest: 'Edit guest',
    includeArchived: 'Include archived',
    information: 'Guest information',
    saveButton: 'Save',
  },
  guests: 'Guests',
  hi: 'Hi',
  integration: {
    activate: 'Activate',
    integrationDetails: 'Integration Details',
    integrationSync: 'Sync Data',
    maqsam: 'Maqsam',
    maqsamAccessKey: 'Access Key',
    maqsamAccessSecret: 'Access Secret',
    maqsamPhoneNumber: 'Phone Number',
    maqsamStep1: 'Step 1: After registering on Maqsam, go to the settings and click on the API tab.',
    maqsamStep2: 'Step 2: Provide your Access Secret and Phone Number in the respective fields.',
    maqsamStepsHeading: 'Steps to link Maqsam',
    msqsamSettings: 'Maqsam Settings',
    syncContactData: 'Sync Contact Data',
    syncRecordingsData: 'Sync Recordings Data',
    syncSuccess: 'Sync completed successfully',
    unifonic: 'Unifonic',
  },
  integrations: 'Integrations',
  invitation: {
    accept: 'Accept',
    accepted: 'Accepted',
    decline: 'Decline',
    declined: 'Declined',
    dialog: {
      cancel: 'Cancel',
      descriptionAccept: 'By accepting the invitation, you will have access to any information related to the account.',
      descriptionDecline:
        'By declining the invitation, you will NOT have access to any information related to the account.',
      heading: 'Are you sure?',
    },
    expired: 'Expired',
    pending: 'Pending',
  },
  invitations: 'Invitations',
  invoice: {
    addInvoiceInformation: 'Add invoicing information (optional)',
    downloadInvoice: 'Download invoice',
    invoiceDate: 'Invoice date',
    invoiceDescription: 'Invoice description',
    invoiceInformation: 'Invoicing information',
    paymentMethod: 'Payment method',
    pdfFile: 'PDF file',
    totalAmount: 'Total amount',
    zatcaRegistrationNumber: 'VAT Registration Number',
    zatcaTaxpayerAddress: 'Taxpayer Address',
    zatcaTaxpayerName: 'Taxpayer Name',
  },
  listings: 'Listings',
  'listings & services': 'Listings & Services',
  listingsViews: 'Website visits',
  loginScreen: {
    'The mobile number field is required.': 'The mobile number field is required.',
    'The password field is required.': 'The password field is required.',
    'The provided credentials are incorrect.': 'The provided credentials are incorrect.',
    mobile_number: 'Mobile number',
    password: 'Password',
  },
  logout: 'Logout',
  maktbFeatures: {
    API: {
      accountingApps: 'API with accounting apps',
      chatApps: 'API with chat apps',
      marketingApps: 'API with marketing apps',
      smsApps: 'API with sms apps',
    },
    other: {
      buildingApps: 'Building app in iOS and Android *for an additional fee',
    },
    platform: {
      deals: 'Unlimited number of deals',
      exportData: 'Export data to excel',
      language: 'Support Arabic and English',
      payments: 'Activate online payments',
      properties: 'Unlimited number of properties',
      propertyRequests: 'Activate property requests',
      requests: 'Unlimited number of requests',
      roles: 'Customize user roles',
      typesOfProperties: 'Support all types of properties',
      users: 'Unlimited number of users',
      whatsapp: 'Activate Whatsapp',
    },
    website: {
      customDomain: 'Custom domain',
      nuzulDomain: 'Nuzul domain xxx.nuzul.app',
      specialWebsiteUI: 'Special website & UI design',
    },
  },
  management: 'Management',
  marketing: 'Marketing',
  member: {
    cancel: 'Cancel',
    card: 'Show membership card',
    edit: 'Edit',
    editURLSuccessfully: 'The link has been edited successfully',
    failureEditMember: 'Error occurred while editing the member',
    failureRemoveMember: 'Error occurred while removing the member',
    invitations: 'Invitations',
    invite: 'Invite',
    inviteMember: 'Invite member',
    inviteTitle: 'Who are you inviting?',
    memberLink: "Member's link on the website",
    members: 'Members',
    removeMemberConfirmation: 'Are you sure you want to remove this member?',
    saveButton: 'Save',
    successEditMember: "Member's role edited successfully",
    successRemoveMember: 'Member removed successfully',
  },
  membership: 'Membership',
  meter: 'meter',
  navbar: {
    nuzulPayActivation: 'Activate 🚀',
    upgradeButton: 'Upgrade 🚀',
  },
  no: 'No',
  notes: {
    activities: 'Activity',
    activitiesAndComments: 'All',
    cancelButton: 'Cancel',
    deleteNote: 'Delete note',
    deleteNoteConfirmation: 'Are you sure you want to delete this note?',
    editNote: 'Edit Note',
    editSave: 'Save',
    logNoteButton: 'Log note',
    noteDeleted: 'Note deleted successfully',
    noteEdited: 'edited',
    noteSent: 'Note logged successfully',
    noteUpdated: 'Note Updated successfully',
    notes: 'Notes',
    notesDescription: 'Add notes to keep track of your work.',
  },
  notifications: {
    dealAssigned:
      '[{{clientName}}] has been assigned to you by [{{assignedByName}}] for the [{{propertyType}}] property',
    dealAssignedTitle: '📋 Deal Assigned',
    delete: 'Delete',
    deleteAll: 'Delete all',
    deleteAllConfirmation: 'Are you sure you want to delete all notifications?',
    deleteSuccess: 'Notification deleted successfully',
    downPayment: 'Client [{{clientName}}] has reserved unit number [{{unitNumber}}]',
    downPaymentTitle: '💰 Unit Down Payment',
    empty: "No new notifications. You're all set!",
    invitationAccepted: 'User [{{userName}}] accepted your invitation to join the team [{{workspaceName}}]',
    invitationAcceptedTitle: '✅ Invitation Accepted',
    markAllAsRead: 'Mark all as read',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    newClientDeal: '[{{clientName}}] expressed interest in the [{{propertyType}}] property',
    newClientDealTitle: '🏡 New Client Deal',
    newClientReservation: '[{{clientName}}] wants to reserve the [{{propertyType}}] property',
    newClientReservationTitle: '🏡 New Client Reservation',
    newPropertyRequest: 'Client [{{clientName}}] is looking for [{{propertyType}}]',
    newPropertyRequestTitle: '🏠 New Property Request',
    note: '[{{content}}], by: [{{userName}}]',
    noteTitle: '📝 Note',
    serviceRequest: 'Client [{{clientName}}] has requested service [{{serviceName}}]',
    serviceRequestTitle: '💰 Service Request',
    tenantInvitation: 'You have been invited to join the team [{{workspaceName}}]',
    tenantInvitationTitle: '✉️ Team Invitation',
    unread: 'You have {{count}} unread notifications',
  },
  number: 'No. #',
  order: {
    activatePaySubscription: 'Activate Nuzul Pay Now 🚀',
    cancel: 'Cancel',
    cancelButton: 'Cancel Order',
    canceled: 'Canceled',
    completed: 'Completed',
    create: 'Create',
    createOrder: 'Create Order',
    customer: 'Client Name',
    downPayment: 'Down payment',
    down_payment: 'Down Payment',
    downloadInvoice: 'Download Invoice',
    editPageTitle: 'Order Information',
    orderDate: 'Order Date',
    paymentLink: 'Payment Link',
    paymentMethod: 'Payment Method',
    paymentMethods: {
      bankTransfer: 'Bank Transfer',
      cash: 'Cash',
      online: 'Online',
      uponArrival: 'Upon Arrival',
    },
    pending_payment: 'Pending Payment',
    propertyOrder: 'Ordered Property',
    selectService: 'Select service',
    service: 'Service',
    serviceRequest: 'Service Request',
    status: 'Status',
    successCancellation: 'Order canceled successfully',
    successCreation: 'Order created successfully',
    totalAmount: 'Total Amount',
    type: 'Order Type',
  },
  orders: 'Orders',
  paidPlan: 'Paid Plan',
  pay: {
    activation: {
      aoc: 'Articles of Association',
      applePayRate: 'Based on used card',
      bankCert: 'Bank Certificate',
      contactMessage: 'Send the required documents to the following email address',
      crCert: 'Commercial Registration Certificate',
      faalCert: 'REGA/FAL Certificate',
      fileSize: 'The maximum file size is 500 KB, and the file must be in PDF format',
      filesSubmission: 'Files Submission',
      filesSubmissionCaption: 'Please upload the required documents based on the category.',
      freelanceCert: 'Freelance.sa Certificate',
      individual: 'Individual',
      institutionCompany: 'Institution/Company',
      institutionOrCompany: 'Institutions or Companies',
      motIwaaCert: 'MOT/IWAA Certificate',
      nationalAddress: 'National Address',
      ownerId: 'Owner national Id',
      ownersIds: 'All owners national Id',
      process: 'Activation request',
      rates: 'Rates',
      rejectMsg: 'Your application has been rejected. Please re-upload a scanned official document.',
      requirements: 'Requirements',
      reviewMsg: 'Your information and documents will be reviewed within 3 business days.',
      selectFile: 'Drop or select file',
      selectFileCaption: 'Drop files here or click to browse through your device.',
      submissionDate: 'Submission Date:',
      submit: 'Submit for validation',
      thankYou: 'Thank you for submitting your application.',
      title: 'Nuzul pay activation',
      vat: 'VAT is not included',
    },
    salesGrowth: 'Sales growth',
    transaction: {
      amount: 'Amount',
      client: 'Client',
      date: 'Date',
      orderNumber: 'Order number',
      status: 'Status',
    },
  },
  plans: {
    basic: 'Basic',
    enterprise: 'Enterprise',
    pro: 'Pro',
  },
  platformFeatures: 'Platform features',
  project: {
    add: 'Add',
    addProject: 'Create Project',
    addUnit: 'Add unit',
    addUnits: 'Add units',
    addUnlimitedProjects: 'Add unlimited number of projects',
    archiveButton: 'Archive',
    area: 'Area',
    availabilityStatus: 'Availability status',
    city: 'City',
    copiedUrl: 'Copied!',
    copyUrl: 'Copy the link',
    descriptionAr: 'Project description (Arabic)',
    descriptionEn: 'Project description (English)',
    district: 'District',
    electricWarranty: 'Electric warranty',
    features: 'Features',
    includeArchived: 'Include archived',
    information: 'Project information',
    name: 'Project name',
    nameAr: 'Project name (Arabic)',
    nameEn: 'Project name (English)',
    navToProjectPage: 'Navigate to project page',
    numberOfApartments: 'number of apartments',
    numberOfBuildings: 'number of buildings',
    numberOfStores: 'number of stores',
    numberOfTownhouse: 'number of townhouse',
    numberOfUnites: 'number of unites',
    numberOfVillas: 'number of villas',
    plumbingWarranty: 'Plumbing warranty',
    project: 'Project',
    projectPdfUrl: 'Project profile link',
    publishedOnWebsite: 'Publish on your website',
    saveButton: 'Save',
    searchLabel: 'Search by project name',
    standaloneUrl: 'Project exclusive link',
    structuralWarranty: 'Structural warranty',
    successCreation: 'Project created successfully',
    successUpdate: 'Project information updated successfully',
    unarchiveButton: 'Unarchive',
    units: 'Project units',
    videoAndPdf: 'Project video and profile',
    views: 'Views',
    virtualTourUrl: 'Virtual tour url',
  },
  projects: 'Projects',
  properties: 'Properties',
  removalConfirmation: 'Are you sure you want to remove this match?',
  remove: 'Remove',
  request: {
    addService: 'Add request',
    cancelButton: 'Cancel',
    closeButton: 'Close request',
    closeRequestConfirmation: 'Are you sure you want to close this request?',
    closed: 'Closed',
    closedBy: 'Closed by',
    closedSuccessfully: 'Request closed successfully',
    closingReason: 'Closing reason',
    confirmCloseRequest: 'Confirm close request',
    confirmConvertToDeal: 'Confirm convert to deal',
    contactName: 'Client Name',
    convertToDeal: 'Convert to deal',
    convertToDealRequestConfirmation: 'Are you sure you want to convert this request to a deal?',
    converted: 'Converted to deal',
    convertedBy: 'Converted by',
    convertedSuccessfully: 'Request converted to deal successfully',
    editPageTitle: 'Property Request Information',
    facts: 'Facts',
    message: 'Client message',
    new: 'New',
    status: 'Status',
    statusTitle: 'Status',
  },
  requests: 'Property Requests',
  resetPasswordScreen: {
    'After checking the mobile number was not found. Kindly register first.':
      'After checking the mobile number was not found. Kindly register first.',
  },
  sales: 'Sales',
  search: 'Search',
  service: {
    addService: 'Add service',
    basePrice: 'Price',
    cancelButton: 'Cancel',
    createButton: 'Add',
    createPageTitle: 'Create service',
    descriptionAr: 'Service description (Arabic)',
    descriptionEn: 'Service description (English)',
    editPageTitle: 'Edit service',
    finalPrice: 'Final Price',
    informationAr: 'Service information in Arabic',
    informationEn: 'Service information in English',
    isFree: 'Free Service',
    nameAr: 'Service name (Arabic)',
    nameEn: 'Service name (English)',
    price: 'Price (SAR)',
    pricing: 'Pricing',
    publishedOnNuzulApp: 'Publish on Nuzul map (soon)',
    publishedOnWebsite: 'Publish on your website',
    publishedStatus: 'Visibility',
    saveButton: 'Save',
    successCreation: 'Service created successfully',
    successUpdate: 'Service information updated successfully',
    taxPercentage: 'Tax percentage',
  },
  services: 'Services',
  setting: {
    accountInformation: 'Update account information',
    accountInformationTitle: 'Account Information',
    accountSubscription: 'Manage your subscriptions',
    accountSubscriptionTitle: 'Subscriptions',
    paymentSetting: {
      accountNumber: 'Account number',
      addBankAccount: 'Add bank account',
      bank: 'Bank',
      bankAccounts: 'Bank Accounts',
      bankTransfer: 'Bank Transfer',
      beneficiaryName: 'Beneficiary name',
      beneficiaryNameAr: 'Beneficiary name (Arabic)',
      beneficiaryNameEn: 'Beneficiary name (English)',
      cancelButton: 'Cancel',
      cash: 'Cash',
      createButton: 'Create',
      iban: 'IBAN',
      onlinePayment: 'Online Payment',
      paymentMethods: 'Activate payment methods',
      requiresNuzulPay: 'Requires Nuzul Pay',
      swift: 'SWIFT (optional)',
      title: 'Payment Settings',
    },
    paymentSettings: 'Manage your payment settings',
    paymentSettingsTitle: 'Payment Settings',
    propertyRequest: {
      activationDescription: 'Activate property search request on your website',
      activationListingDescription: 'Activate property listing request on your website',
      activationTitle: 'Activation',
      addRequest: 'Add request',
      allowedLocations: 'Allowed locations',
      allowedPropertyTypes: 'Allowed property types',
      buy: 'Buy',
      category: 'Category',
      commercial: 'Commercial',
      configurations: 'Configurations',
      createButton: 'Create request',
      districtsHelper: 'Select all that apply',
      editPageTitle: 'Edit request',
      failedUpdate: 'Error occurred while updating the request',
      propertyTypes: 'Property types',
      propertyTypesHelper: 'Select all that apply',
      publish: 'Publish',
      purposes: 'Purposes',
      rent: 'Rent',
      residential: 'Residential',
      saveButton: 'Save',
      successUpdate: 'Request updated successfully',
      title: 'Manage your property requests',
    },
    propertyRequests: 'Manage your property requests features',
    propertyRequestsTitle: 'Property Requests',
  },
  settings: 'Settings',
  siyaha: {
    listingsAndReservations: 'Listings & Reservations',
    numberOfReservations: 'Number of Reservations',
    numberOfUnits: 'Number of Units',
    reservation: {
      calendar: 'Calendar',
      cancel: 'Cancel',
      cancelButton: 'Cancel Reservation',
      cancelSuccess: 'Reservation canceled successfully',
      checkInDate: 'Check In Date',
      checkOutDate: 'Check Out Date',
      confirmButton: 'Confirm Reservation',
      confirmPaymentButton: 'Confirm Payment',
      confirmSuccess: 'Reservation confirmed successfully',
      create: 'Create',
      createReservation: 'Create reservation',
      dateOfBirth: 'Date of Birth',
      dates: 'Date',
      guestEmail: 'Email',
      guestMobileNumber: 'Mobile Number',
      guestName: 'Guest Name',
      guestsInformation: 'Guests Information',
      numberOfNights: 'Number of Nights',
      paymentMethod: 'Payment Method',
      paymentMethods: {
        bankTransfer: 'Bank Transfer',
        cash: 'Cash',
        online: 'Online',
        uponArrival: 'Upon Arrival',
      },
      paymentStatus: 'Payment Status',
      paymentStatuses: {
        canceled: 'Canceled',
        completed: 'Completed',
        paid: 'Paid',
        pending_payment: 'Pending Payment',
      },
      propertyInformation: 'Property Information',
      reservationDetails: 'Reservation Details',
      statues: {
        canceled: 'Canceled',
        confirmed: 'Confirmed',
        on_hold: 'On Hold',
        pending: 'Pending',
      },
      status: 'Status',
      successCreation: 'Reservation created successfully',
      unitNumber: 'Unit Number',
    },
    reservations: 'Reservations',
    unit: {
      createUnitButton: 'Create unit',
      creationTitle: 'Unit information',
      dailyRatePrice: 'Daily Rate Price',
      dailyRateWeekendPrice: 'Daily Rate Weekend Price',
    },
    units: 'Units',
  },
  siyahaFeatures: {
    API: {
      accountingApps: 'API with accounting apps',
      airbnbAPI: 'API with Airb&b',
      almosaferAPI: 'API with Almosafer',
      bookingAPI: 'API with Booking',
      chatApps: 'API with chat apps',
      marketingApps: 'API with marketing apps',
      smsApps: 'API with sms apps',
    },
    other: {
      buildingApps: 'Building app in iOS and Android *for an additional fee',
    },
    platform: {
      bookings: 'Unlimited number of bookings',
      exportData: 'Export data to excel',
      language: 'Support Arabic and English',
      payments: 'Activate online payments',
      properties: 'Unlimited number of properties',
      propertyRequests: 'Activate property requests',
      requests: 'Unlimited number of requests',
      roles: 'Customize user roles',
      typesOfProperties: 'Support all types of properties',
      users: 'Unlimited number of users',
      whatsapp: 'Activate Whatsapp',
    },
    website: {
      customDomain: 'Custom domain',
      nuzulDomain: 'Nuzul domain xxx.nuzul.app',
      specialWebsiteUI: 'Special website & UI design',
    },
  },
  soon: 'soon',
  sqm: 'sqm',
  subscription: {
    checkout: {
      applyButton: 'Apply',
      couponCode: 'Coupon code',
      couponTitle: 'Got a coupon?',
      payOffline: 'Create Order',
      payOnline: 'Pay Now',
      subtitle: 'How would like to pay?',
      title: 'Payment',
    },
    plans: {},
  },
  team: 'Team',
  teams: {
    accepted: 'Accepted',
    associateRole: 'Associate',
    canceled: 'Canceled',
    dateJoined: 'Date joined',
    dateSent: 'Date sent',
    declined: 'Declined',
    inviteScreen: {
      'The user is already a member.': 'The user is already a member.',
      'There is a already pending invitation.': 'There is a already pending invitation.',
      successCreation: 'Invitation sent successfully',
    },
    managerRole: 'Manager',
    memberRole: 'Member',
    mobileNumber: 'Mobile number',
    name: 'Name',
    pending: 'Pending',
    remove: 'Remove',
    removeAll: 'Remove the member permanently',
    removeAllInfo: 'The member will be removed from all deals, requests, clients and properties.',
    role: 'Role',
    roleChange: 'Change Role',
    status: 'Status',
  },
  to: 'To',
  total: 'Total',
  transaction: {},
  transactions: 'Transactions',
  unit: {
    advertiser: {
      adNumber: 'Advertisement number (from REGA)',
      advertiser: 'Advertiser',
      broker: 'Broker',
      licenseNumber: 'License number (from REGA)',
      name: 'Advertiser type',
      owner: 'Owner',
      title: 'Advertiser information',
    },
    annuallyPrice: 'Price annually',
    apartments: 'Units',
    archive: 'Archive',
    archiveButton: 'Archive',
    area: 'Area',
    availability: 'Availability',
    availabilityStatus: 'Availability status',
    availabilityStatusAndDownPayment: 'Availability and Payment',
    available: 'Available',
    balconies: 'Balcony',
    basement: 'Basment',
    bathrooms: 'Bathrooms',
    bedrooms: 'Bedrooms',
    building: 'Building',
    building_apartment: 'Apartment in a building',
    buy: 'Buy',
    category: 'Category',
    changeOwner: 'Change Owner',
    city: 'City',
    clearAll: 'Clear all',
    commercial: 'Commercial',
    compound: 'Compound',
    copiedUrl: 'Copied!',
    copyUrl: 'Copy the link',
    coverPhoto: 'Cover Photo',
    coverPhotoFileSize: 'Maximum file size is 500KB, and the file type must be JPG, PNG or JPEG.',
    createUnitButton: 'Create unit',
    createdBy: 'Created by',
    creationTitle: 'Unit information',
    dailyLivingRoom: 'Daily Living room',
    description: 'Unit description',
    descriptionAr: 'Unit description in Arabic',
    descriptionEn: 'Unit description in an English',
    diningRooms: 'Dining room',
    district: 'District',
    downPayment: 'Down payment',
    downPaymentOnline: 'Down payment',
    downPaymentPrice: 'Down payment price',
    downPaymentPriceHelperText: 'Set availability status to "Available" to enable down payment',
    downPaymentPriceVAT: 'Down payment with VAT',
    downPaymentTaxPercentage: 'Down payment VAT percentage',
    downPaymentTotalWithVAT: 'Total with VAT',
    downloadAsPdf: 'Download as PDF',
    driverRooms: 'Driver rooms',
    duplex: 'Duplex',
    duplicate: 'Duplicate',
    duplicateSuccess: 'Unit [{{unit_number}}] has been duplicated successfully',
    east: 'East',
    eastWest: 'East West',
    education: 'Education',
    electricity: 'Electricity',
    elevators: 'Elevator',
    facade: 'Facade',
    factory: 'Factory',
    facts: 'Facts',
    factsAndFeatures: 'Facts and Features',
    farm: 'Farm',
    farming: 'Farming',
    features: 'Features',
    floorNumber: 'Floor number',
    floors: 'Floors',
    formalLivingRoom: 'Formal Living room',
    gardens: 'Garden',
    health: 'Health',
    hotel: 'Hotel',
    includeArchived: 'Include archived',
    information: 'Unit information',
    isBankTransferPaymentEnabled: 'Enable bank transfer',
    isCashPaymentEnabled: 'Enable cash payment',
    isMemberWhatsAppNumberEnabled: "Enable member's WhatsApp (in case of using their link)",
    isOnlinePaymentEnabled: 'Enable online payment',
    isWafiAd: 'Selling on map? (Wafi)',
    isWhatsAppNumberEnabled: 'Enable WhatsApp number',
    istraha: 'Istraha',
    kingBeds: 'King beds',
    kitchens: 'Kitchen',
    land: 'Land',
    length: 'Unit length',
    link: 'Unit link',
    listingType: 'Type',
    location: 'Location',
    maidRooms: 'Maid rooms',
    majlisRooms: 'Majlis',
    mansion: 'Mansion',
    media: 'Media',
    monthlyPrice: 'Price monthly',
    mulhaqRooms: 'Mulhag',
    name_ar: 'Unit name in Arabic',
    name_en: 'Unit name in English',
    navToPropertyPage: 'Navigate to property page',
    navigateToUnit: 'Navigate to this property',
    noDeals: 'No deals for this property.',
    north: 'North',
    northEast: 'North East',
    northEastWest: 'North East West',
    northSouth: 'North South',
    northSouthEast: 'North South East',
    northSouthEastWest: 'North South East West',
    northSouthWest: 'North South West',
    northWest: 'North West',
    numberOfGuests: 'Guests',
    numberOfKids: 'Kids',
    office: 'Office',
    owner: 'Owner',
    parkingBay: 'Parking',
    paymentMethods: 'Payment methods',
    photos: 'Photos',
    planNumber: 'Plan number',
    plotNumber: 'Plot number',
    pool: 'Pool',
    price: 'Price',
    publishedOnNuzulApp: 'Publish on Nuzul map (soon)',
    publishedOnWebsite: 'Publish on your website',
    publishedStatus: 'Visibility',
    publishedStatusMustEnablePayment: 'You must enable at least one payment method to publish the unit',
    purpose: 'Purpose',
    quarterlyPrice: 'Price quarterly',
    rent: 'Rent',
    rented: 'Rented',
    rentingPrice: 'Renting Price',
    reserved: 'Reserved',
    residential: 'Residential',
    resort: 'Resort',
    saveButton: 'Save',
    searchLabel: 'Search by ID or unit number',
    sell: 'Sell',
    sellingPrice: 'Selling Price',
    semiAnnuallyPrice: 'Price semi-annually',
    services: 'Services',
    sewage: 'Sewage',
    showPricingRules: 'Customize pricing',
    showPricingRulesTooltip: 'Set different prices for specific dates: e.g: holidays',
    showroom: 'Showroom',
    singleBeds: 'Single beds',
    sold: 'Sold',
    soon: 'Soon',
    sort: 'Sort',
    sortHelperText: 'Higher number will be displayed first',
    south: 'South',
    southEast: 'South East',
    southEastWest: 'South East West',
    southWest: 'South West',
    storage: 'Storage',
    storageRooms: 'Storage',
    store: 'Store',
    stores: 'Stores',
    streetWidthEast: 'Street width east',
    streetWidthNorth: 'Street width north',
    streetWidthSouth: 'Street width south',
    streetWidthWest: 'Street width west',
    tower_apartment: 'Apartment in a tower',
    townhouse: 'Townhouse',
    translateFromArabic: 'Translate using AI ✨',
    type: 'Type',
    unarchiveButton: 'Unarchive',
    unavailable: 'Unavailable',
    unit_number: 'Unit number',
    usage: 'Usage',
    usageAndServices: 'Usage and services',
    video: 'Video',
    videoAndVirtualTour: 'Video and Virtual tour',
    views: 'Views',
    villa: 'Villa',
    villa_apartment: 'Apartment in a villa',
    villa_floor: 'Floor in a villa',
    wafiLicenseNumber: 'License number (from Wafi)',
    water: 'Water',
    website: 'Website',
    west: 'West',
    whatsappNumber: 'WhatsApp number',
    whatsappNumberExample: 'Example: +966501234567',
    width: 'Unit width',
    yearBuilt: 'Year Built',
    youtubeVideoUrl: 'YouTube video URL',
  },
  units: 'Units',
  unspecified: 'Unspecified',
  user: {
    email: 'Email',
    female: 'Female',
    gender: 'Gender',
    male: 'Male',
    mobileNumber: 'Mobile number',
    name: 'Name',
    personalInformation: 'Personal Information',
    profile: 'Profile',
    saveButton: 'Save',
  },
  userMenu: {
    yourInvitations: 'Your invitations',
    yourProfile: 'Your profile',
    yourWorkspaces: 'Your accounts',
  },
  verifyCodeScreen: {
    'Attempts exceeded, request another code.': 'Attempts exceeded, request another code.',
    'Please provide correct code.': 'Please provide correct code.',
    'You cannot send verification now, try again after 1 minute.':
      'You cannot send verification now, try again after 1 minute.',
  },
  versionNumber: 'v',
  web: {
    domain: {
      access: 'Your current website is accessible through the domain name below:',
      contact: 'You can contact us to update your own domain name.',
      email: 'Email',
      information: 'Domain information',
      option1: 'Option 1',
      option2: 'Option 2',
      subtitle: 'edit your domain settings',
      title: 'Domain name',
      whatsapp: 'WhatsApp',
    },
    identity: {
      colors: 'Colors',
      darkTheme: 'Dark theme',
      enableDarkTheme: 'Enable dark theme',
      exampleButton: 'This is a primary button',
      exampleButtonSecondary: 'This is a secondary button',
      exampleButtonTertiary: 'This is a hyperlink',
      logo: 'Logo',
      primaryColor: 'Primary color',
      secondaryColor: 'Secondary color',
      subtitle: 'Update your Logo and colors',
      title: 'Identity',
    },
    integrationInformation: 'Integration information',
    integrationTitle: 'Integration',
    licenses: {
      hrsdFreelanceNumber: 'HRSD freelance number',
      hrsdFreelanceNumberDescription: 'Issuing authority: Ministry of Human Resources and Social Development',
      mcCrNumber: 'MCI/MCR number',
      mcCrNumberDescription: 'Issuing authority: Ministry of Commerce',
      motIwaaNumber: 'MOT/IWAA number',
      motIwaaNumberDescription: 'Issuing authority: Ministry of Turism',
      regaFal: 'REGA/FAL number',
      regaFalDescription: 'Issuing authority: Real Estate General Authority',
      title: 'Government licenses',
      zatcaCertificateNumber: 'ZATCA VAT certificate number',
      zatcaCertificateNumberDescription: 'Issuing authority: General Authority of Zakat and Tax',
    },
    map: {
      activationDescription: 'Activate the map on your website',
      activationTitle: 'Activation',
      configurations: 'Map configurations',
      darkTheme: 'Map dark mode',
      enableDarkTheme: 'Enable map dark mode',
      enableNearbyServices: 'Enable property nearby services',
      initMapPosition: 'Set initial map position',
      nearbyServices: 'Property nearby services',
      resetMapPosition: 'Reset marker position',
      saveButton: 'Save',
      subtitle: 'Manage your map features',
      title: 'Map',
    },
    pages: {
      privacy: {
        activationTitle: 'Activation',
        edit: 'Edit the privacy policy',
        privacyAr: 'Privacy Policy (Arabic)',
        privacyEn: 'Privacy Policy (English)',
        subtitle: 'edit the privacy policy page of your website',
        successUpdate: 'Privacy policy updated successfully',
        title: 'Privacy Policy',
      },
      saveButton: 'Save',
      subtitle: 'edit your website pages',
      terms: {
        activationTitle: 'Activation',
        edit: 'Edit the terms and conditions',
        subtitle: 'edit the terms and conditions page of your website',
        successUpdate: 'Terms and conditions updated successfully',
        termsAr: 'Terms and Conditions (Arabic)',
        termsEn: 'Terms and Conditions (English)',
        title: 'Terms and Conditions',
      },
      title: 'Pages',
    },
    socialMediaLinks: {
      email: 'Email',
      gTagRadio: 'Activate Google Tag Manager',
      googleTagManager: 'Google Tag Manager measurement ID',
      instagram: 'Instagram',
      metaPixel: 'Facebook Pixel ID',
      metaPixelRadio: 'Activate Facebook Pixel',
      phoneNumber: 'Phone number',
      snapchat: 'Snapchat',
      snapchatPixel: 'Snapchat Pixel ID',
      snapchatPixelRadio: 'Activate Snapchat Pixel',
      subtitle: 'edit your social media links',
      tiktok: 'TikTok',
      tiktokPixel: 'TikTok Pixel ID',
      tiktokPixelRadio: 'Activate TikTok Pixel',
      title: 'Social media links',
      whatsapp: 'WhatsApp',
      whatsappFloatingButton: 'Activate WhatsApp floating button',
      x: 'X platform',
    },
  },
  website: 'Website',
  websiteFeatures: 'Website features',
  weekDays: {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  },
  workspace: {
    certDescription: 'Certificate numbers will be visible at the bottom of the website and invoices',
    companyLogo: 'Logo',
    confirmLeave: 'Confirm Leave',
    currentPackage: 'Current Package',
    currentPlan: 'Current Plan',
    day: ' Day',
    daysLeftOnPlan: 'Days left on plan',
    daysLeftOnSubscription: 'Days left on subscription',
    default: 'Default',
    generalInformation: 'General Information',
    leave: 'Leave',
    nameAr: 'Account Name (Arabic)',
    nameEn: 'Account Name (English)',
    numberOfUsers: 'Number of users',
    paymentHistory: 'Payment History',
    plans: 'Payment plans',
    save: 'Save',
    setDefault: 'Set Default',
    settings: 'Settings',
    subscriptions: 'Subscriptions',
    table: {
      active: 'Active',
      amount: 'Amount',
      canceled: 'Canceled',
      completed: 'Completed',
      endDate: 'End Date',
      expired: 'Expired',
      one_time: '-',
      orderDate: 'Order Date',
      package: 'Package',
      pending_payment: 'Pending Payment',
      period: 'Period',
      quarterly: 'Quarterly',
      startDate: 'Start Date',
      status: 'Status',
      subscription_monthly: ' Monthly',
      subscription_quarterly: ' Quarterly',
      subscription_trial: ' Trial',
      subscription_yearly: ' Yearly',
      total_amount_with_tax: 'Total Amount with Tax',
      type: ' Type',
      yearly: 'Yearly',
    },
    uploadLogo: 'Upload Logo',
  },
  workspaceTitle: 'Account',
  workspaces: 'Accounts',
  year: 'Year',
  yes: 'Yes',
};

export default en;
