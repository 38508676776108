/* eslint no-useless-catch: 0 */
import { ENV, HOST_API, PORT_API } from '../../config';
import axios from '../../utils/axios';

const tenantUrl = localStorage.getItem('tenant_url');

class TicketServices {
  getUrl() {
    return HOST_API;
  }

  getTenantUrl() {
    const protocol = ENV === 'local' ? 'http' : 'https';
    const url = `${protocol}://${tenantUrl}${PORT_API}`;
    return url;
  }

  getNuzulPayTicket = async () => {
    try {
      return await axios.get(`${this.getTenantUrl()}/api/tickets/pay`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  updateNuzulPayTicket = async (payload) => {
    try {
      return await axios.post(`${this.getTenantUrl()}/api/tickets/pay`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  submitNuzulPayTicket = async (payload) => {
    try {
      return await axios.post(`${this.getTenantUrl()}/api/tickets/pay/submit`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };
}

export { TicketServices };
export default new TicketServices();
