import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useState } from 'react';

import useLocales from '../hooks/useLocales';

const countryCodes = [{ code: '+966', label: '🇸🇦 +966', country: 'SA' }];

const MobilePhoneInput = ({ onChange }) => {
  const { translate } = useLocales();
  const [phone, setPhone] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const defaultCountryCode = countryCodes[0].code;
  const defaultCountry = countryCodes[0].country;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const parsed = parsePhoneNumberFromString(inputValue, defaultCountry);

    if (parsed && parsed.isValid()) {
      const cleaned = parsed.nationalNumber;
      setIsValid(true);
      setPhone(cleaned);
      if (onChange) onChange(cleaned);
    } else {
      setIsValid(false);
      setPhone(inputValue);
      if (onChange) onChange(undefined);
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  return (
    <Box display="flex" flexDirection="row" sx={{ mt: 3 }} style={{ direction: 'ltr' }}>
      <Select value={defaultCountryCode} disabled sx={{ width: 120 }}>
        {countryCodes.map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <TextField
        placeholder="5x xxx xxxx"
        value={phone}
        onChange={handleChange}
        label={translate('auth.login.mobileNumber')}
        onBlur={handleBlur}
        variant="outlined"
        fullWidth
        error={isTouched && !isValid}
        InputProps={{
          autoComplete: 'tel-national',
          endAdornment: isValid && (
            <InputAdornment position="end">
              <CheckCircleIcon style={{ color: 'green' }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default MobilePhoneInput;
