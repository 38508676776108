import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import tOrderServices from './services';

export function* getTOrdersSaga({ payload }) {
  try {
    const response = yield call(tOrderServices.getTOrders, payload);
    yield put(actions.getTOrdersSuccess(response.data));
  } catch (err) {
    yield put(actions.getTOrdersFailure(err));
  }
}

export function* getTOrderByIdSaga({ payload }) {
  try {
    const response = yield call(tOrderServices.getTOrderDetails, payload);
    yield put(actions.getTOrderDetailsSuccess(response.data));
  } catch (err) {
    yield put(actions.getTOrderDetailsFailure(err));
  }
}

export function* createTOrderDownPaymentSaga({ payload }) {
  const { callback, ...values } = payload;

  try {
    const response = yield call(tOrderServices.createTOrderDownPayment, values);
    yield put(actions.createTOrderDownPaymentSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createTOrderDownPaymentFailure(err));
    callback?.('error', err);
  }
}

export function* createTOrderServiceSaga({ payload }) {
  const { callback, ...values } = payload;

  try {
    const response = yield call(tOrderServices.createTOrderService, values);
    yield put(actions.createTOrderServiceSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createTOrderServiceFailure(err));
    callback?.('error', err);
  }
}

export function* setTOrderStatusSaga({ payload }) {
  const { id, callback, ...values } = payload;
  console.log(payload);
  try {
    const response = yield call(tOrderServices.setTOrderStatus, id, values);
    yield put(actions.setTOrderStatusSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.setTOrderStatusFailure(err));
    callback?.('error', err);
  }
}

export function* tOrdersSaga() {
  yield all([takeLatest(actions.getTOrdersRequest, getTOrdersSaga)]);
  yield all([takeLatest(actions.getTOrderDetailsRequest, getTOrderByIdSaga)]);
  yield all([takeLatest(actions.createTOrderDownPaymentRequest, createTOrderDownPaymentSaga)]);
  yield all([takeLatest(actions.createTOrderServiceRequest, createTOrderServiceSaga)]);
  yield all([takeLatest(actions.setTOrderStatusRequest, setTOrderStatusSaga)]);
}
