import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import invoiceServices from './services';

export function* getInvoicesSaga() {
  try {
    const response = yield call(invoiceServices.getInvoices);
    yield put(actions.getInvoicesSuccess(response.data));
  } catch (err) {
    yield put(actions.getInvoicesFailure(err));
  }
}

export function* invoicesSaga() {
  yield all([takeLatest(actions.getInvoicesRequest, getInvoicesSaga)]);
}
