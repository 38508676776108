import TabsUnstyled from '@mui/base/TabsUnstyled';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import VisaIcon from '../../../assets/visa-icon.svg';
import { Loader } from '../../../components/Loader';
import useLocales from '../../../hooks/useLocales';
import CouponsServices from '../../../models/coupon/services';
import ProductsServices from '../../../models/product/services';
import * as SubscriptionActions from '../../../models/subscription/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';
import { PATH_ORDERS } from '../../../routes/paths';
import PlansTable from '../PlansTable';
import {
  HowToPayOuterBoxStyled,
  HowToPayRadioBoxStyled,
  HowToPayRadioButtonStyled,
  HowToPaySubTitleStyled,
  PackageDetailsBox,
  PackagePriceSummaryRow,
  PackageTotalPriceBox,
  PaymentSummaryBoxStyled,
  PayNowRadioButtonStyled,
  Tab,
  TabPanel,
  TabsList,
  TermsNconditionsBox,
  TermsNconditionsTypography,
} from './HowToPay.elements';

export default function HowToPay() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
  }, []);

  const [tab, setTab] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [appliedCouponName, setAppliedCouponName] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [couponValue, setCouponValue] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [discountValue, setDiscountValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [qty, setQty] = useState(1);

  const [loading, setLoading] = useState(true);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);

  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);

  const [zatcaTaxpayerName, setZatcaTaxpayerName] = useState('');
  const [zatcaRegistrationNumber, setZatcaRegistrationNumber] = useState('');
  const [zatcaTaxpayerAddress, setZatcaTaxpayerAddress] = useState('');

  useEffect(() => {
    if (workspaceInformationData) {
      setZatcaTaxpayerName(workspaceInformationData.zatca_taxpayer_name || '');
      setZatcaRegistrationNumber(workspaceInformationData.zatca_registration_number || '');
      setZatcaTaxpayerAddress(workspaceInformationData.zatca_taxpayer_address || '');
    }
  }, [workspaceInformationData]);

  const solution = useLocation().pathname.split('/').pop();

  let productIndex;
  if (solution === 'siyaha') {
    productIndex = 3;
  } else {
    productIndex = 1;
  }

  const currentPrice =
    tab === 0 ? products[productIndex]?.price_monthly_recurring : products[productIndex]?.price_yearly_recurring;
  const subTotal = currentPrice * qty * (tab === 0 ? 1 : 12);

  const vatTotal = (subTotal - appliedDiscount) * (products[productIndex]?.tax_percentage / 100);

  const total = subTotal + vatTotal - appliedDiscount;

  const userCostPerMonthQ = currentPrice;
  const userCostPerMonthY = currentPrice;
  const nextBilling = tab === 0 ? moment().add(30, 'days').format('LL') : moment().add(365, 'days').format('LL');

  const getAllProducts = () => {
    ProductsServices.getProducts()
      .then((res) => {
        setProducts(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const checkCoupon = (code) => {
    CouponsServices.checkCoupon(code)
      .then((res) => {
        setIsApplyingCoupon(false);
        setIsCouponApplied(true);
        setAppliedCouponName(res.data.data.name);
        setAppliedCoupon(res.data.data.code);
        setCouponValue('');
        setDiscountType(res.data.data.discount_type);
        setDiscountValue(res.data.data.discount_value);
        toast.success(translate('cart.Coupon applied successfully'));
      })
      .catch((err) => {
        setIsApplyingCoupon(false);
        if (err.response.status === 422) {
          toast.error(translate('cart.Invalid coupon code'));
        } else {
          toast.error(translate('cart.Something went wrong'));
        }
      });
  };

  useEffect(() => {
    getAllProducts();
  }, [tab]);

  const handleApplyCoupon = () => {
    setIsApplyingCoupon(true);
    setIsCouponApplied(false);
    setAppliedCoupon(null);
    setAppliedCouponName('');
    setAppliedDiscount(0);
    // if coupon is not null
    if (!couponValue) {
      setIsApplyingCoupon(false);
      return;
    }
    checkCoupon(couponValue);
  };

  useEffect(() => {
    if (isCouponApplied) {
      if (discountType === 'percentage') {
        setAppliedDiscount((subTotal * discountValue) / 100);
      }
      if (discountType === 'amount') {
        setAppliedDiscount(discountValue);
      }
    }
  }, [isCouponApplied, tab, discountType, discountValue, appliedDiscount, appliedCoupon, subTotal]);

  function PricingSection() {
    return (
      <Container
        style={{
          overflowX: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          padding: 0,
        }}
      >
        {/* Free Plan */}
        <Grid
          item
          style={{
            width: '50%',
            display: 'inline-block',
            marginRight: '4px',
            marginLeft: '4px',
          }}
          elevation={3}
        >
          <Paper style={{ padding: '20px', minHeight: '500px' }}>
            <Box>
              <Typography variant="h5" gutterBottom>
                {translate('cart.freePlan')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {translate('cart.features')}
              </Typography>
            </Box>
            <List>
              <ListItem>
                <ListItemText primary="اضافة عقارات" secondary="3 وحدات" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة عملاء" secondary="10 عملاء" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة الصفقات" secondary="5 صفقات" />
              </ListItem>
              <ListItem>
                <ListItemText primary="موقع الكتروني خاص بعروضك العقارية" secondary="متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="نطاق الكتروني مخصص (Domain Name)" secondary="غير متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة الفريق" secondary="عدد غير محدود" />
              </ListItem>
              <ListItem>
                <ListItemText primary="صلاحيات الفريق (مالك، مدير، مساعد، عضو)" secondary="غير متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="ربط التواصل عن طريق الواتساب" secondary="غير متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="ربط ادوات التحليل مع قوقل (Google Analytics)" secondary="غير متوفر" />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid
          item
          style={{
            width: '50%',
            display: 'inline-block',
            marginRight: '4px',
            marginLeft: '4px',
          }}
          elevation={3}
        >
          <Paper style={{ padding: '20px', minHeight: '500px' }}>
            <Typography variant="h5" gutterBottom>
              {translate('cart.paidPlan')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {translate('cart.features')}
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="اضافة عقارات" secondary="عدد غير محدود" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة عملاء" secondary="عدد غير محدود" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة الصفقات" secondary="عدد غير محدود" />
              </ListItem>
              <ListItem>
                <ListItemText primary="موقع الكتروني خاص بعروضك العقارية" secondary="متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="نطاق الكتروني مخصص (Domain Name)" secondary="متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="اضافة الفريق" secondary="عدد غير محدود" />
              </ListItem>
              <ListItem>
                <ListItemText primary="صلاحيات الفريق (مالك، مدير، مساعد، عضو)" secondary="متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="ربط التواصل عن طريق الواتساب" secondary="متوفر" />
              </ListItem>
              <ListItem>
                <ListItemText primary="ربط ادوات التحليل مع قوقل (Google Analytics)" secondary="متوفر" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Container>
    );
  }

  const handlePayNow = () => {
    setLoading(true);

    const params = {
      products: solution === 'siyaha' ? [{ product_id: 4, qty }] : [{ product_id: 2, qty }],
      period: tab === 0 ? 'monthly' : 'yearly',
      payment_method: paymentMethod === 0 ? 'online' : 'bank_transfer',
      coupon_code: isCouponApplied ? appliedCoupon : null,
      solution: solution === 'siyaha' ? 'siyaha' : 'digital_office',
      zatca_taxpayer_name: zatcaTaxpayerName || '',
      zatca_registration_number: zatcaRegistrationNumber || '',
      zatca_taxpayer_address: zatcaTaxpayerAddress || '',
      callback: (type, res) => {
        if (type === 'success') {
          // setLoading(false);

          if (paymentMethod === 0) {
            window.open(PATH_ORDERS.order.payment(res.id), '_self');
          }
          if (paymentMethod === 1) {
            navigate(PATH_ORDERS.order.summery(res.id));
          }
        } else {
          setLoading(false);
          console.error({ res });
          toast.error(res.message);
        }
      },
    };

    if (solution === 'siyaha') {
      dispatch(SubscriptionActions.createSiyahaSubscriptionOrderRequest(params));
    } else {
      dispatch(SubscriptionActions.createMaktbSubscriptionOrderRequest(params));
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <HowToPayOuterBoxStyled>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">{translate('Order Information')}</Typography>

                <Box sx={{ marginTop: '29px' }}>
                  <Box>
                    <PaymentSummaryBoxStyled>
                      <Typography style={{ marginTop: '14px', marginBottom: '14px' }}>
                        {translate('Save 20% when you pay annually')}
                      </Typography>

                      <Box sx={{ width: '100%', marginTop: '14px' }}>
                        <Typography variant="p" sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '400' }}>
                          {translate('Billing period')}
                        </Typography>
                        <TabsUnstyled defaultValue={tab} value={tab} onChange={(e, val) => setTab(val)}>
                          <TabsList>
                            <Tab style={{ fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif' }}>
                              {translate('Monthly')}
                            </Tab>
                            <Tab style={{ fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif' }}>
                              {translate('Annually')} (-20%)
                            </Tab>
                          </TabsList>
                          <TabPanel value={tab}>
                            <PackageDetailsBox>
                              <PackageTotalPriceBox>
                                <PackagePriceSummaryRow>
                                  <Typography
                                    variant="p"
                                    sx={{ fontWeight: '600', fontSize: '16px', color: '#919EAB' }}
                                    style={{ fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif' }}
                                  >
                                    {translate('SAR')}
                                    {tab === 0 ? userCostPerMonthQ?.toFixed(2) : userCostPerMonthY?.toFixed(2)}{' '}
                                    {translate('/a month')}
                                  </Typography>
                                </PackagePriceSummaryRow>
                                <Typography
                                  variant="p"
                                  sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '400' }}
                                  style={{ fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif' }}
                                >
                                  {translate('Next billing will be')} {nextBilling}
                                </Typography>
                              </PackageTotalPriceBox>
                              <Box sx={{ paddingTop: '14px' }}>
                                <PackagePriceSummaryRow>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      color: '#919EAB',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('Subtotal')}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: '500',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('SAR')} {subTotal.toFixed(2)}
                                  </Typography>
                                </PackagePriceSummaryRow>
                                {isCouponApplied && (
                                  <PackagePriceSummaryRow>
                                    <Typography
                                      variant="p"
                                      sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        color: '#6aa84f',
                                        fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                      }}
                                    >
                                      {translate('Discount')} ({appliedCouponName})
                                    </Typography>
                                    <Typography
                                      variant="p"
                                      sx={{
                                        fontSize: '16px',
                                        fontWeight: '500',
                                        fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                        color: '#6aa84f',
                                      }}
                                    >
                                      {translate('SAR')} {appliedDiscount.toFixed(2)}-
                                    </Typography>
                                  </PackagePriceSummaryRow>
                                )}
                                <PackagePriceSummaryRow>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      color: '#919EAB',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('VAT')}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: '500',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('SAR')} {vatTotal.toFixed(2)}
                                  </Typography>
                                </PackagePriceSummaryRow>
                                <PackagePriceSummaryRow>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontWeight: '600',
                                      fontSize: '16px',
                                      color: '#919EAB',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('Total')}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: '800',
                                      fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
                                    }}
                                  >
                                    {translate('SAR')} {total.toFixed(2)}
                                  </Typography>
                                </PackagePriceSummaryRow>
                              </Box>
                            </PackageDetailsBox>
                          </TabPanel>
                        </TabsUnstyled>
                      </Box>

                      <Typography style={{ marginTop: '14px', marginBottom: '14px' }}>
                        {translate('subscription.checkout.couponTitle')}
                      </Typography>

                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={9}>
                          <TextField
                            fullWidth
                            label={translate('subscription.checkout.couponCode')}
                            variant="outlined"
                            color={solution === 'siyaha' ? 'siyaha' : 'primary'}
                            onChange={(e) => {
                              setCouponValue(e.target.value);
                            }}
                            value={couponValue}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            fullWidth
                            variant="outlined"
                            color={solution === 'siyaha' ? 'siyaha' : 'primary'}
                            onClick={() => {
                              handleApplyCoupon(true);
                            }}
                            disabled={isApplyingCoupon}
                          >
                            {isApplyingCoupon && <CircularProgress size={30} />}
                            {!isApplyingCoupon && translate('subscription.checkout.applyButton')}
                          </Button>
                        </Grid>
                      </Grid>
                    </PaymentSummaryBoxStyled>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="h4">{translate('subscription.checkout.title')}</Typography>
                  <Box sx={{ marginTop: '29px' }}>
                    <HowToPaySubTitleStyled variant="p">
                      {translate('subscription.checkout.subtitle')}
                    </HowToPaySubTitleStyled>
                    <Box sx={{ marginTop: '1rem' }}>
                      <RadioGroup
                        onChange={(e, val) => {
                          setPaymentMethod(parseInt(val, 10));
                        }}
                        defaultValue={paymentMethod}
                        value={paymentMethod}
                      >
                        <HowToPayRadioBoxStyled className="payment-item-selected">
                          <FormControlLabel
                            value={0}
                            control={<HowToPayRadioButtonStyled />}
                            label={translate('Online payment')}
                          />
                          <img src={VisaIcon} alt="" />
                        </HowToPayRadioBoxStyled>
                        {/*   <HowToPayRadioBoxStyled className="payment-item-selected">
                          <FormControlLabel
                            value={1}
                            control={<HowToPayRadioButtonStyled />}
                            label={translate('Bank transfer')}
                          />
                          <img src={AlmaIcon} alt="" />
                        </HowToPayRadioBoxStyled>
                      */}
                      </RadioGroup>

                      <HowToPaySubTitleStyled variant="p">
                        {translate('invoice.addInvoiceInformation')}
                      </HowToPaySubTitleStyled>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item xs={12}>
                          <TextField
                            name="zatca_taxpayer_name"
                            label={translate('invoice.zatcaTaxpayerName')}
                            value={zatcaTaxpayerName}
                            onChange={(e) => setZatcaTaxpayerName(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="zatca_registration_number"
                            label={translate('invoice.zatcaRegistrationNumber')}
                            value={zatcaRegistrationNumber}
                            onChange={(e) => setZatcaRegistrationNumber(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="zatca_taxpayer_address"
                            label={translate('invoice.zatcaTaxpayerAddress')}
                            value={zatcaTaxpayerAddress}
                            onChange={(e) => setZatcaTaxpayerAddress(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Box>
                        <PayNowRadioButtonStyled
                          onClick={() => {
                            handlePayNow();
                          }}
                          variant="contained"
                        >
                          {paymentMethod === 0
                            ? translate('subscription.checkout.payOnline')
                            : translate('subscription.checkout.payOffline')}
                        </PayNowRadioButtonStyled>
                        <TermsNconditionsBox>
                          <TermsNconditionsTypography variant="p">
                            {translate('By proceeding, you agree to the')}
                          </TermsNconditionsTypography>
                          <a
                            style={{ color: '#919EAB', lineHeight: '18px', fontSize: '12px', fontWeight: '400' }}
                            href="https://nuzul.app"
                          >
                            {translate('Terms and Conditions')}
                          </a>
                        </TermsNconditionsBox>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ borderStyle: 'dashed', marginTop: 10 }} />
            {<PlansTable solution={solution} />}
            {/* <PricingSection /> */}
          </Container>
        </HowToPayOuterBoxStyled>
      )}
    </>
  );
}
